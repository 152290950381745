import ERROR_CODES from 'errorCodes.json';

const errorMessages = {
  password_reset_in_progress: 'Invalid email and/or password. Click Forgot Password to reset your password.',
  incorrect_credentials: 'Invalid email and/or password.',
  incorrect_mfa_credentials: 'Invalid authentication code.',
  internal_server_error: 'An unexpected error occurred.',
};

function credentialsTypes(type) {
  return type === 'MFA' ? 'authentication code' : 'email and/or password';
}

function ttlToMinutes(ttl) {
  const minutes = Math.ceil(ttl / 60);
  return minutes > 1 ? `${minutes} minutes` : `1 minute`;
}

export function translateErrorCodeToMessage(error, type = '') {
  if (error.loginError === ERROR_CODES.LOGIN_LOCKED_OUT_FOR_MANY_ATTEMPTS) {
    return `You have entered an incorrect ${credentialsTypes(type)} too many times. Please wait ${ttlToMinutes(
      error.ttl
    )} and try again.`;
  }
  return errorMessages[error.loginError] ? errorMessages[error.loginError] : errorMessages['internal_server_error'];
}
