import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from '../SignUp.module.scss';
import { Button, Form } from '@solarwinds-cloud/cloud-ui';
import { EmailInput } from './EmailInput';
import { NameInput } from './NameInput';
import { PasswordInput } from './PasswordInput';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorLabel } from '../../../components';
import { isPasswordValid } from '@solarwinds-cloud/swicus-validation';

export class SignUpForm extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    passwordRef: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChangeName: PropTypes.func.isRequired,
    handleChangePassword: PropTypes.func.isRequired,
    handlePasswordError: PropTypes.func.isRequired,
    handleNameError: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Form loading={this.props.loading} onSubmit={this.props.handleSubmit} error={this.hasFormError()}>
        {this.renderErrorIfNecessary()}
        {this.renderInputs()}
        {this.renderSubmitButton()}
      </Form>
    );
  }

  renderErrorIfNecessary = () => {
    return this.props.errors.signUp && <ErrorMessage message={this.props.errors.signUp} />;
  };

  renderInputs = () => {
    return (
      <Fragment>
        <EmailInput email={this.props.email} />
        {this.renderNameInput('firstName', 'First name')}
        {this.renderNameInput('lastName', 'Last name')}
        {this.renderPasswordInput()}
      </Fragment>
    );
  };

  renderNameInput = (name, placeholder) => {
    return (
      <Fragment>
        <NameInput
          id={name}
          name={name}
          placeholder={placeholder}
          value={this.props[name]}
          hasError={!!this.props.errors[name]}
          handleChange={this.props.handleChangeName(name)}
          handleError={this.props.handleNameError(name)}
        />
        {this.props.errors[name] && <ErrorLabel>{this.props.errors[name]}</ErrorLabel>}
      </Fragment>
    );
  };

  renderPasswordInput = () => {
    // make component uncontrolled to avoid exposing value by "inspect element"
    return (
      <Fragment>
        <PasswordInput
          id="password"
          name="password"
          password={this.props.password}
          passwordRef={this.props.passwordRef}
          hasError={!!this.props.errors.password}
          handleChange={this.props.handleChangePassword}
          handleError={this.props.handlePasswordError}
        />
        {this.props.errors.password && <ErrorLabel>{this.props.errors.password}</ErrorLabel>}
      </Fragment>
    );
  };

  renderSubmitButton = () => {
    return (
      <Button
        type="submit"
        id="submit-button"
        className={styles.createBtn}
        size="big"
        primary
        fluid
        disabled={!this.isFormValid() || this.hasFormError()}
      >
        Create profile
      </Button>
    );
  };

  hasFormError = () => {
    const { errors } = this.props;
    return !!(errors.signUp || errors.password || errors.firstName || errors.lastName);
  };

  isFormValid = () => {
    return !!(this.props.email && this.props.firstName && this.props.lastName && isPasswordValid(this.props.password));
  };
}
