import PropTypes from 'prop-types';
import React from 'react';
import { RouterLink } from '../../../../../components';

export const ForgotPassword = props => {
  return (
    <div className={props.className}>
      <RouterLink to={props.resetPasswordLink}>Forgot Password?</RouterLink>
    </div>
  );
};

ForgotPassword.propTypes = {
  resetPasswordLink: PropTypes.string,
  className: PropTypes.string,
};
