import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ChangePassword.module.scss';

import { ErrorLabel } from '../../components';
import { Grid, Form, Button, Ref } from '@solarwinds-cloud/cloud-ui';
import { isPasswordValid } from '@solarwinds-cloud/swicus-validation';
import { formStates } from './formStates';
import { InvalidTokenError } from '../../api/actions/changePassword/changePassword';
import { passwordRestrictionsMessage } from '../../utils/utils';

export default class ChangePasswordForm extends Component {
  static propTypes = {
    resetToken: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: {
        password: '',
        passwordConfirmation: '',
        changePassword: '',
      },
    };
    this.passwordInput = React.createRef();
    this.passwordConfirmationInput = React.createRef();
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} method="POST" error={this.hasErrors()} data-test-form>
        <Grid verticalAlign="middle">
          <Grid.Column width={16}>
            {this.renderPasswordInput()}
            {this.renderPasswordErrorIfNecessary()}
            {this.renderPasswordConfirmationInput()}
            {this.renderPasswordConfirmationErrorIfNecessary()}
          </Grid.Column>
          {this.renderConfirmButton()}
        </Grid>
      </Form>
    );
  }

  renderPasswordInput() {
    // make component uncontrolled and use Ref to avoid exposing value by "inspect element"
    return (
      <Ref innerRef={this.passwordInput}>
        <Form.Input
          error={this.state.errors.password.length > 0}
          icon="locked"
          iconPosition="left"
          name="password"
          aria-label="Password"
          onChange={this.handleChange}
          placeholder="Password"
          required
          type="password"
          value={this.state.password}
          data-test-password-input
        />
      </Ref>
    );
  }

  renderPasswordConfirmationInput() {
    // make component uncontrolled and use Ref to avoid exposing value by "inspect element"
    return (
      <Ref innerRef={this.passwordConfirmationInput}>
        <Form.Input
          error={this.state.errors.passwordConfirmation.length > 0}
          icon="locked"
          iconPosition="left"
          name="passwordConfirmation"
          aria-label="Confirm password"
          onChange={this.handleChange}
          placeholder="Confirm password"
          required
          type="password"
          data-test-confirm-password-input
        />
      </Ref>
    );
  }

  renderConfirmButton() {
    return (
      <Grid.Column width={16} className={styles.submitButton}>
        <Button
          type="submit"
          fluid
          size="big"
          primary
          data-test-confirm-button
          disabled={this.areFieldsEmpty() || this.hasErrors() || this.isLoading()}
          loading={this.isLoading()}
        >
          Save new password
        </Button>
      </Grid.Column>
    );
  }

  handleSubmit = async () => {
    if (!this.hasErrors()) {
      await this.changePassword();
    }
  };

  renderPasswordErrorIfNecessary() {
    return (
      this.state.errors.password.length > 0 && (
        <ErrorLabel data-test-password-error-label>{this.state.errors.password}</ErrorLabel>
      )
    );
  }

  renderPasswordConfirmationErrorIfNecessary() {
    return (
      this.state.errors.passwordConfirmation.length > 0 && (
        <ErrorLabel data-test-password-confirmation-error-label>{this.state.errors.passwordConfirmation}</ErrorLabel>
      )
    );
  }

  async changePassword() {
    try {
      this.startLoading();
      const data = { password: this.getPassword(), resetToken: this.props.resetToken };
      await this.props.changePassword(data);
      this.changeFormState(formStates.PASSWORD_UPDATED);
    } catch (error) {
      this.stopLoading();
      if (error instanceof InvalidTokenError) {
        this.changeFormState(formStates.INVALID_TOKEN);
      } else {
        this.setErrors({ changePassword: 'An unknown error occurred' });
      }
    }
  }

  startLoading() {
    this.changeFormState(formStates.LOADING);
  }

  stopLoading() {
    this.changeFormState(formStates.INITIAL);
  }

  setErrors(errors) {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        ...errors,
      },
    }));
    this.props.onError(this.state.errors.changePassword);
  }

  handleChange = (event, data) => {
    this.setState({
      errors: this.validatePasswords({
        password: this.getPassword(),
        passwordConfirmation: this.getPasswordConfirmation(),
      }),
    });
  };

  validatePasswords = passwords => {
    const newPassword = passwords.password;
    const confirmationPassword = passwords.passwordConfirmation;
    var errors = {
      password: '',
      passwordConfirmation: '',
    };

    if (!isPasswordValid(passwords.password)) {
      errors.password = passwordRestrictionsMessage;
    }

    const passwordsMatch = newPassword === confirmationPassword;
    if (confirmationPassword !== '' && !passwordsMatch) {
      errors.passwordConfirmation = 'Passwords do not match';
    }
    return errors;
  };

  hasErrors() {
    return !!Object.values(this.state.errors).find(error => error.length > 0);
  }

  areFieldsEmpty() {
    return !this.getPassword() || !this.getPasswordConfirmation();
  }

  isLoading() {
    return this.props.isLoading;
  }

  changeFormState(formState) {
    this.props.onChange(formState);
  }

  getPassword() {
    return this.passwordInput.current?.querySelector('input')?.value || '';
  }

  getPasswordConfirmation() {
    return this.passwordConfirmationInput.current?.querySelector('input')?.value || '';
  }
}
