import React from 'react';
import WithTheme from '../../../components/WithTheme';

const RightSide = ({ styles, themeProps }) => {
  const Component = themeProps.theme.RightSideComponent;

  return <Component styles={styles} />;
};

export default WithTheme(RightSide);
