import React from 'react';
import solarwindsLogo from '../../themes/shared/assets/solarwinds-logo-white.svg';
import { CenterLayout } from '../index';
import styles from './SlingshotLayout.module.scss';
import { Image } from '@solarwinds-cloud/cloud-ui';

export const SlingshotLayout = ({ children }) => (
  <div>
    <CenterLayout className={styles.wider}>
      <div>
        <Image inline className={styles.logo} alt="Logo" src={solarwindsLogo} data-test-product-logo />
        <div className={styles.brandLine} />

        <div className={styles.wrapper}>{children}</div>
      </div>
    </CenterLayout>
  </div>
);
SlingshotLayout.displayName = 'SlingshotLayout';
