import { sortBy, fromPairs, map } from 'lodash';

export function focus(inputElement) {
  if (inputElement && inputElement.current) {
    inputElement.current.focus();
  }
}

export function redirectTo(url) {
  window.location.href = url;
}

export function sortObjectByKeysAlphabetically(object) {
  const keys = Object.keys(object);
  const sortedKeys = sortBy(keys);
  return fromPairs(map(sortedKeys, key => [key, object[key]]));
}

export function generateRandomString(length) {
  const allowedChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += allowedChars.charAt(Math.floor(Math.random() * allowedChars.length));
  }
  return text;
}

export function convertSecondsToDays(seconds) {
  return Math.floor(seconds / (60 * 60 * 24));
}

export function generateRequestID() {
  return `swicus.ui.${generateRandomString(8)}`;
}

export const passwordRestrictionsMessage =
  'Password must be between 8 and 72 characters long and must contain at least one uppercase character, lowercase character, number, and symbol';
