import React from 'react';
import { RouterLink } from '../../../../components';
import styles from './SamlLink.module.scss';
import { Button } from '@solarwinds-cloud/cloud-ui';

export const SamlLink = props => {
  return (
    <>
      <span>Or</span>
      <div data-test-block="samlLink" className={styles.container}>
        <RouterLink data-test-link="samlLink" to={props.samlLink} className={styles.ssoLink}>
          <Button id={styles.samlButton} type="submit" size="big" fluid inverted>
            Log in with SSO
          </Button>
        </RouterLink>
      </div>
    </>
  );
};
