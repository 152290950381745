import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';

export const PasswordInput = props => {
  return (
    <Form.Field required>
      <Input
        icon="locked"
        iconPosition="left"
        name="password"
        aria-label="password"
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder="Password"
        required
        ref={props.input}
        type="password"
        value={props.value}
        data-test-password-input
        defaultValue={props.defaultValue}
      />
    </Form.Field>
  );
};

PasswordInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  input: PropTypes.any,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};
