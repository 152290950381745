import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@solarwinds-cloud/cloud-ui';

export const ErrorMessage = ({ message }) => <Message error content={message} data-test-error-message />;

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

ErrorMessage.displayName = 'ErrorMessage';
