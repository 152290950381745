import React from 'react';
import { fetchBackendConfig } from '../../api/actions';
import { PropTypes } from 'prop-types';

const { Provider, Consumer } = React.createContext({});

export class BackendConfigProvider extends React.Component {
  static propTypes = {
    fetchBackendConfig: PropTypes.func.isRequired,
  };

  state = {
    backendConfig: {},
  };

  componentDidMount() {
    this.storeBackendConfig();
  }

  async storeBackendConfig() {
    try {
      const backendConfig = await this.props.fetchBackendConfig();
      this.setState({ backendConfig });
    } catch (e) {
      //eslint-disable-next-line no-console
      console.log('Error while fetching external scripts:', e);
    }
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export const WithBackendConfig = Component => {
  return class WithBackendConfig extends React.Component {
    render() {
      return <Consumer>{providedState => <Component {...this.props} {...providedState} />}</Consumer>;
    }
  };
};

export default props => <BackendConfigProvider {...props} fetchBackendConfig={fetchBackendConfig} />;
