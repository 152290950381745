import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formStates } from './';
import ROUTES from 'routes.json';
import ResetPasswordForm from './components/reset-password-form/ResetPasswordForm';
import ResetPasswordSuccessScreen from './components/reset-password-success-screen/ResetPasswordSuccessScreen';
import { resetPassword } from '../../api/actions';
import { DocumentTitle } from '../../components/DocumentTitle';
import { useLocation } from 'react-router-dom';
import { RouterLink } from '../../components';
import styles from './ResetPassword.module.scss';
import { SlingshotLayout } from '../../layouts/SlingshotLayout/SlingshotLayout';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';

export class ResetPassword extends Component {
  static propTypes = {
    invalidResetPasswordToken: PropTypes.bool.isRequired,
    resetPassword: PropTypes.func.isRequired,
    firstTime: PropTypes.bool.isRequired,
  };

  state = {
    email: '',
    errors: {
      email: '',
      invalidResetPasswordToken: false,
      resetPassword: '',
    },
    formState: formStates.INITIAL,
  };

  render() {
    if (this.props.firstTime) {
      return (
        <DocumentTitle title={'SolarWinds Cloud'}>
          <div data-test-page-name="ResetPasswordFirstTime">
            <SlingshotLayout>{this.renderContent()}</SlingshotLayout>
          </div>
        </DocumentTitle>
      );
    } else {
      return (
        <DocumentTitle title={'SolarWinds Cloud'}>
          <div data-test-page-name="ResetPassword">
            <CardLayout>
              {this.renderContent()}
              {this.getLoginLink()}
            </CardLayout>
          </div>
        </DocumentTitle>
      );
    }
  }

  renderContent = () => {
    if (this.isFormInSuccessState()) {
      return this.renderResetPasswordSuccessContent();
    } else {
      return this.renderResetPasswordForm();
    }
  };

  renderResetPasswordSuccessContent() {
    return <ResetPasswordSuccessScreen email={this.state.email} />;
  }

  renderResetPasswordForm() {
    return (
      <ResetPasswordForm
        isLoading={this.isFormInLoadingState()}
        email={this.state.email}
        errors={this.getErrors()}
        handleSubmit={this.handleSubmit}
        handleChangeEmail={this.handleChangeEmail}
        handleEmailError={this.handleEmailError}
        firstTime={this.props.firstTime}
      />
    );
  }

  getLoginLink() {
    return (
      <span>
        Return to{' '}
        <RouterLink className={styles.link} to={ROUTES.LOGIN}>
          Login
        </RouterLink>
        .
      </span>
    );
  }

  isFormInSuccessState() {
    return this.state.formState === formStates.SUCCESS;
  }

  isFormInLoadingState() {
    return this.state.formState === formStates.LOADING;
  }

  handleSubmit = async () => {
    try {
      this.startLoading();
      await this.props.resetPassword(this.state.email);
      this.setFormSuccessState();
    } catch (error) {
      this.setErrors({ resetPassword: 'An unknown error occurred' });
      this.stopLoading();
    }
  };

  startLoading() {
    this.setState({ formState: formStates.LOADING });
  }

  stopLoading() {
    this.setState({ formState: formStates.INITIAL });
  }

  setFormSuccessState() {
    this.setState({ formState: formStates.SUCCESS });
  }

  setErrors(errors) {
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        ...errors,
      },
    }));
  }

  handleEmailError = error => {
    this.setState(prevState => ({ errors: { ...prevState.errors, email: error } }));
  };

  handleChangeEmail = email => {
    this.setState(prevState => ({ email, errors: { ...prevState.errors, email: '' } }));
  };

  getErrors() {
    return {
      email: this.state.errors.email,
      invalidResetPasswordToken: this.props.invalidResetPasswordToken,
      resetPassword: this.state.errors.resetPassword,
    };
  }
}

const ResetPasswordWithInvalidResetPasswordToken = Component => props => {
  return (
    <Component
      invalidResetPasswordToken={IsInvalidResetPasswordToken()}
      resetPassword={resetPassword}
      firstTime={IsFirstTime()}
      {...props}
    />
  );

  function IsInvalidResetPasswordToken() {
    return Boolean(useLocation()?.state?.invalidResetPasswordToken);
  }

  function IsFirstTime() {
    return Boolean(useLocation()?.state?.firstTime);
  }
};

export default ResetPasswordWithInvalidResetPasswordToken(ResetPassword);
