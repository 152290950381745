import getFormQuery from '../../../utils/getFormQuery';
import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';

export async function samlRedirect(post, email, preferedOrgID, loginQueryParams) {
  const result = await post(ENDPOINTS.SAML_REDIRECT, {
    email: email,
    preferedOrgID: preferedOrgID,
    loginQueryParams,
  });

  assertResponseSuccess(result);
  return result.body.redirectUri;
}

function assertResponseSuccess(res) {
  if (res.status === 404 || res.status === 400) {
    throw new Error('Login was not successful. Please contact support for more information.');
  } else if (res.status !== 200 || (res.status === 200 && !res.body.redirectUri)) {
    throw new Error('Server error, try again later.');
  }
}

export default (email, preferedOrgID) => samlRedirect(post, email, preferedOrgID, getFormQuery(window.location.search));
