import queryString from 'query-string';

const SSO_PARAMS = ['response_type', 'scope', 'client_id', 'state', 'redirect_uri', 'nonce'];

function getFormQuery(search) {
  const urlParams = queryString.parse(search);
  return SSO_PARAMS.map(param => (urlParams[param] ? `${param}=${encodeURIComponent(urlParams[param])}` : ''))
    .filter(param => param.length)
    .join('&');
}

export default getFormQuery;
