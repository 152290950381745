import React from 'react';

import styles from './CenterLayout.module.scss';

const CenterLayout = ({ children, className }) => {
  const css = [styles.inner, className].join(' ').trim();

  return (
    <div className={styles.wrapper}>
      <div className={css}>{children}</div>
    </div>
  );
};

CenterLayout.displayName = 'CenterLayout';

export default CenterLayout;
