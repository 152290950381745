import React from 'react';
import PropTypes from 'prop-types';
import style from './ActionButton.module.scss';
import { Icon } from '@solarwinds-cloud/cloud-ui';

export const ActionButton = ({ mainText, descriptionText, url, testTag }) => {
  return (
    <a className={style.actionButtonBox} data-test-link={testTag} href={url}>
      <div className={style.actionButtonContentHolder} data-test-button={testTag}>
        <div className={style.mainText} data-test-main-text={testTag}>
          {mainText}
        </div>
        <div className={style.descriptionText}>{descriptionText}</div>
      </div>
      <Icon name="chevron-right" />
    </a>
  );
};

ActionButton.propTypes = {
  mainText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  testTag: PropTypes.string.isRequired,
};

ActionButton.displayName = 'ActionButton';
