import { generateRequestID } from '../utils';
import { getResponseBody } from './';

export async function post(endpoint, body) {
  const response = await fetch(endpoint, {
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      'x-request-id': generateRequestID(),
    },
    method: 'POST',
    credentials: 'include',
  });

  const responseBody = await getResponseBody(response);

  return {
    status: response.status,
    body: responseBody,
  };
}
