import getFormQuery from '../../../utils/getFormQuery';
import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';
import { assertResponseSuccess } from '../../../utils/loginError/loginError';

export async function loginWithCredentials(post, loginQueryParams, email, password) {
  const result = await post(ENDPOINTS.LOGIN, {
    email: email,
    password: password,
    loginQueryParams,
  });

  assertResponseSuccess(result);

  return result.body;
}

export default (email, password) => loginWithCredentials(post, getFormQuery(window.location.search), email, password);
