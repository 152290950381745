import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';

export async function changePassword(post, data) {
  const result = await post(ENDPOINTS.RESET, {
    password: data.password,
    reset_token: data.resetToken,
  });

  assertResponseSuccess(result);
}

function assertResponseSuccess(res) {
  const errorMessage = res.body && res.body.errorMessage;
  if (res.status === 404 || res.status === 400) {
    throw new InvalidTokenError('Token is invalid.');
  } else if (res.status >= 400) {
    throw new Error(errorMessage || 'Server error, try again later.');
  }
}

export class InvalidTokenError extends Error {}

export default data => changePassword(post, data);
