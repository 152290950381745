import React from 'react';
import PropTypes from 'prop-types';

import styles from '../SignUp.module.scss';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';
import { isPasswordValid } from '@solarwinds-cloud/swicus-validation';
import { passwordRestrictionsMessage } from '../../../utils/utils';

export const PasswordInput = ({ handleChange, handleError, password, passwordRef, hasError, name }) => {
  return (
    <Form.Field className={styles.field} error={hasError}>
      <Input
        type="password"
        error={hasError}
        className={styles.input}
        name={name}
        placeholder="Password"
        icon="locked"
        iconPosition="left"
        onChange={handleChange}
        onBlur={validatePassword}
        ref={passwordRef}
      />
    </Form.Field>
  );

  function validatePassword() {
    if (!isPasswordValid(password)) {
      handleError(passwordRestrictionsMessage);
    }
  }
};

PasswordInput.displayName = 'PasswordInput';
PasswordInput.propTypes = {
  password: PropTypes.string.isRequired,
  passwordRef: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};
