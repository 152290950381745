import ERROR_CODES from 'errorCodes.json';

class LoginError extends Error {
  constructor(message, ttl) {
    super(message);
    this.ttl = ttl;
  }
}

export function assertResponseSuccess(res) {
  if (res.body && res.body.errorCode === ERROR_CODES.LOGIN_LOCKED_OUT_FOR_MANY_ATTEMPTS) {
    throw new LoginError(res.body.errorCode, res.body.errorValues.ttl);
  } else if (res.status >= 300) {
    throw new Error((res.body && res.body.errorCode) || 'Server error, try again later.');
  }
}
