import React from 'react';
import WithTheme from '../../components/WithTheme';
import { get } from 'lodash';

const Main = ({ children, themeProps }) => {
  const productKey = get(themeProps, 'theme.key', '').toLowerCase();
  return (
    <main data-qa="layout-main" className={productKey}>
      {children}
    </main>
  );
};

export default WithTheme(Main);
