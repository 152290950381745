import React, { Component } from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import { ProductLinks } from '../../components/ProductLinks/ProductLinks';

export default class InvalidInvitation extends Component {
  render() {
    return (
      <div data-test-page-name="InvalidInvitation">
        <CardLayout>
          <h1 className="warning">Sorry...</h1>
          <p>
            Your invitation link is invalid or has expired. <br />
            Talk to your administrator for more information.
          </p>
          <p>
            If you want to try our products without a team <br />
            you can sign up below:
          </p>
          <ProductLinks />
        </CardLayout>
      </div>
    );
  }
}
