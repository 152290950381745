import React from 'react';

import styles from './ResetPasswordSuccessScreen.module.scss';

export const ResetPasswordSuccessScreen = ({ email }) => (
  <p className={styles.emailSentText}>
    Instructions to reset your password have been sent to {email}. You should receive this email within the next 5
    minutes.
  </p>
);

ResetPasswordSuccessScreen.displayName = 'ResetPasswordSuccessScreen';

export default ResetPasswordSuccessScreen;
