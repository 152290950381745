import { withRouter, Link } from 'react-router-dom';
import React from 'react';

export const RouterLink = ({ className, to, children, location }) => {
  return (
    <Link className={className} to={{ pathname: to, search: location && location.search }}>
      {children}
    </Link>
  );
};

export default withRouter(RouterLink);
