import React, { Fragment } from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import ROUTES from 'routes.json';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';
import { productList } from '../../config';
import ProductLoginLinks from '../../components/ProductLoginLinks/ProductLoginLinks';
import style from './VerifiedEmail.module.scss';

export function VerifiedEmail(props) {
  const loginLink = getLoginLink(props.product);
  const productName = getProductName(props.product);

  return (
    <div data-test-page-name="VerifiedEmail">
      <CardLayout logoLink={ROUTES.PRODUCT}>
        <p className="lg">Your email has been successfully verified.</p>
        {renderLoginLinkIfPossible(productName, loginLink)}
        <div className={style.crossProductNote} data-test-verified-email-text-note>
          By signing up for <span className={style.noWrap}>SolarWinds {productName}</span> you automatically have access
          to the entire suite of <span className={style.noWrap}>SolarWinds</span> Application Management products,
          including:
        </div>
        <ProductLoginLinks />
      </CardLayout>
    </div>
  );

  function getLoginLink(product) {
    return get(props.backendConfig, ['loginLink', product]);
  }

  function getProductName(product) {
    const productList = props.productList;
    return productList[product] ? productList[product].name : undefined;
  }
}

function renderLoginLinkIfPossible(productName, loginLink) {
  if (loginLink) {
    return (
      <Fragment>
        <p className="lg">
          <a href={loginLink} data-test-product-link>
            Continue
          </a>{' '}
          to <span data-test-product-name>{productName}</span>
        </p>
      </Fragment>
    );
  }
}

const EnhancedVerifiedEmail = props => <VerifiedEmail {...props} productList={productList} />;

export default WithBackendConfig(EnhancedVerifiedEmail);
