import React from 'react';
import PropTypes from 'prop-types';
import { Message } from '@solarwinds-cloud/cloud-ui';
import { redirectTo } from '../../../../../../utils/utils';
// eslint-disable-next-line no-unused-vars
import styles from './LocalAuthDisabledError.module.scss';
import { samlRedirect } from '../../../../../../api/actions';
import ROUTES from 'routes.json';

export function LocalAuthDisabledError(props) {
  const { email, redirectTo, samlRedirect } = props;

  return render();

  function render() {
    return (
      <Message error data-test-error-message="localAuthDisabled">
        In order to continue, please{' '}
        <span onClick={() => handleSamlRedirect()} data-test-link="localAuthDisabled" className={styles.link}>
          Log in with Single Sign On
        </span>
        .
      </Message>
    );
  }

  async function handleSamlRedirect() {
    try {
      const redirectUri = await samlRedirect(email);
      redirectTo(redirectUri);
    } catch (e) {
      redirectTo(ROUTES.ERROR);
    }
  }
}

LocalAuthDisabledError.propTypes = {
  email: PropTypes.string.isRequired,
  redirectTo: PropTypes.func.isRequired,
  samlRedirect: PropTypes.func.isRequired,
};

export default props => (
  <LocalAuthDisabledError email={props.email} redirectTo={redirectTo} samlRedirect={samlRedirect} />
);
