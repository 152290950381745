import React from 'react';
import PropTypes from 'prop-types';

import styles from '../SignUp.module.scss';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';
import isValidName from '../../../utils/isValidName/isValidName';

export const NameInput = ({ placeholder, value, handleChange, handleError, hasError, name }) => {
  return (
    <Form.Field className={styles.field} error={hasError}>
      <Input
        className={styles.input}
        icon="user"
        iconPosition="left"
        name={name}
        value={value}
        placeholder={placeholder}
        onBlur={() => validate()}
        onChange={(event, data) => handleChange(data.value)}
      />
    </Form.Field>
  );

  function validate() {
    if (!isValidName(value)) {
      handleError('Enter a valid name');
    }
  }
};

NameInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
NameInput.displayName = 'NameInput';
