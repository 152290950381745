import { withRouter } from 'react-router-dom';
import React from 'react';
import { productList as themeConfig } from '../../config';
import PropTypes from 'prop-types';
import { parseQueryString } from '../../utils/parseQueryString/parseQueryString';

const ThemeContext = React.createContext({});

class ThemeProvider extends React.Component {
  static nullTheme = {
    name: 'nullTheme',
  };
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.element,
    themeConfig: PropTypes.object,
    parseQueryString: PropTypes.func,
  };

  state = { theme: undefined, oldTheme: undefined };

  componentDidMount() {
    this.tryToLoadTheme(this.props.location.search);
    this.props.history.listen(location => this.tryToLoadTheme(location.search, location.pathname));
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.state.theme !== undefined && this.props.children}
      </ThemeContext.Provider>
    );
  }

  tryToLoadTheme(queryString) {
    const themeName = this.parseThemeFromQueryString(queryString);
    this.loadTheme(themeName || this.state.oldTheme);
  }

  loadTheme = themeName => {
    const selectedTheme = this.props.themeConfig[themeName];

    if (selectedTheme) {
      this.setTheme(selectedTheme);
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Theme ${themeName} is not known theme or route is not whitelisted! We are using null instead.`);
      this.setTheme(ThemeProvider.nullTheme);
    }
  };

  setTheme(theme) {
    this.setState(state => ({ theme: theme, oldTheme: state.theme }));
  }

  parseThemeFromQueryString(queryString) {
    let parsed = this.props.parseQueryString('client_id', queryString);
    if (parsed == null) {
      return parsed;
    }
    return parsed.replace('_temp', '');
  }
}

export const EnhancedThemeProvider = props => {
  return <ThemeProvider {...props} themeConfig={themeConfig} parseQueryString={parseQueryString} />;
};

export const EnhancedThemeProviderWithRouter = withRouter(EnhancedThemeProvider);

const ThemeConsumer = ThemeContext.Consumer;

export { ThemeConsumer, ThemeProvider };
