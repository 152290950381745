import React, { Component, Fragment } from 'react';
import { Form, Grid } from '@solarwinds-cloud/cloud-ui';

import { isEmailValid } from '@solarwinds-cloud/swicus-validation';
import { EmailInput } from '../../../../components';
import styles from './ResetPasswordForm.module.scss';
import PropTypes from 'prop-types';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { SubmitButton } from './submit-button/submit-button';

const GRID_WIDTH = 16;

export default class ResetPasswordForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleEmailError: PropTypes.func.isRequired,
    handleChangeEmail: PropTypes.func.isRequired,
    firstTime: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Fragment>
        {!this.props.firstTime && <p>Forgot your password?</p>}
        {this.renderInvalidResetPasswordTokenInfoIfNecessary()}

        <Form onSubmit={this.props.handleSubmit} method="POST" error={this.hasFormError()}>
          {this.renderFormBody()}
        </Form>
      </Fragment>
    );
  }

  renderFormBody() {
    const { handleChangeEmail, errors, email } = this.props;
    return (
      <Grid verticalAlign="middle">
        <Grid.Column width={GRID_WIDTH}>
          {this.renderResetPasswordErrorIfNecessary(errors)}
          {this.renderInvalidResetPasswordTokenErrorIfNecessary(errors)}
          {this.renderEmailInput(handleChangeEmail, email)}
        </Grid.Column>
        <Grid.Column width={GRID_WIDTH} className={styles.resetButton}>
          <SubmitButton disabled={this.isSubmitDisabled()} loading={this.props.isLoading} />
        </Grid.Column>
      </Grid>
    );
  }

  renderEmailInput() {
    const { email, handleChangeEmail, handleEmailError } = this.props;
    return <EmailInput autoFocus value={email} onChange={handleChangeEmail} onError={handleEmailError} />;
  }

  renderInvalidResetPasswordTokenInfoIfNecessary() {
    if (this.props.firstTime) {
      return (
        <Fragment>
          <h1>OOPS! YOUR TOKEN HAS EXPIRED</h1>
          <p>Don't worry! Enter your email below and we will send you instructions for completing your registration.</p>
        </Fragment>
      );
    }
  }

  renderInvalidResetPasswordTokenErrorIfNecessary(errors) {
    if (errors.invalidResetPasswordToken && !this.props.firstTime) {
      return <ErrorMessage message="Your password reset link is invalid or has expired." />;
    }
  }

  renderResetPasswordErrorIfNecessary(errors) {
    return errors.resetPassword && <ErrorMessage message={errors.resetPassword} className={styles.errorMessage} />;
  }

  isSubmitDisabled() {
    return this.isEmptyEmailInput() || !isEmailValid(this.props.email) || this.props.isLoading;
  }

  isEmptyEmailInput() {
    return this.props.email.length === 0;
  }

  hasFormError() {
    const errors = this.props.errors;
    return Object.keys(errors).some(k => errors[k]);
  }
}
