import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { productList } from '../../config';
import ROUTES from 'routes.json';
import { Redirect } from 'react-router-dom';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import { UserInitials } from '../../components';
import { LogosFooter } from '../../components';
import { ActionButton } from './components/ActionButton';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';

import style from './LoginOrSignUp.module.scss';

export class LoginOrSignUp extends Component {
  static propTypes = {
    product: PropTypes.string.isRequired,
    redirectTo: PropTypes.func.isRequired,
    backendConfig: PropTypes.object.isRequired,
    fetchUser: PropTypes.func.isRequired,
  };

  state = {
    user: { first_name: '', last_name: '', email: '' },
    productName: '',
  };

  componentDidMount = async () => {
    await this.storeUser();
    this.setState({ productName: productList[this.props.product].name });
  };

  render = () => {
    const { user } = this.state;

    if (!this.isEverythingLoaded()) {
      return <span data-test-loading>Loading...</span>;
    } else {
      return (
        <CardLayout wrapperClass={style.wrapper}>
          <div className={style.headerHolder}>
            <UserInitials name={user.first_name} surname={user.last_name} email={user.email} />
            <div className={style.header}>
              <div className={style.headerText}>
                <div>We noticed that you are currently logged in</div>
                <div>
                  as{' '}
                  <span className={style.email} data-test-email="email" title={this.getEmailTooltip(user.email)}>
                    {this.formatEmail(user.email)}
                  </span>
                </div>
              </div>
              <div className={style.headerSubTitle}>How would you like to continue?</div>
            </div>
          </div>

          <div className={style.actionButtonHolder}>
            <ActionButton
              testTag="product-continue"
              url={this.getLoginLink()}
              mainText={`Continue to ${this.state.productName}…`}
              descriptionText="Stay logged in without creating a new account."
            />
            <ActionButton
              testTag="product-sign-in"
              url={`${ROUTES.LOGOUT}?redirect_uri=${this.getSignUpLink()}`}
              mainText={`Continue to the Sign up form…`}
              descriptionText="Log out from current session and create a new account."
            />
          </div>

          <LogosFooter />
        </CardLayout>
      );
    }
  };

  isEverythingLoaded() {
    const { loginLink, signUpLink } = this.props.backendConfig;
    const { user } = this.state;
    return user && user.email && loginLink && signUpLink;
  }

  formatEmail = email => {
    return this.isEmailTooLong(email) ? email.slice(0, 30) + '...' : email;
  };

  getEmailTooltip = email => {
    return this.isEmailTooLong(email) ? email : '';
  };

  isEmailTooLong = email => {
    return email.length > 32;
  };

  storeUser = async () => {
    try {
      const user = await this.props.fetchUser();
      this.setState({ user });
    } catch (e) {
      this.props.redirectTo(ROUTES.PRODUCT);
    }
  };

  getLoginLink = () => {
    const product = this.props.product;
    const backendConfig = this.props.backendConfig;
    return get(backendConfig, 'loginLink.' + product);
  };

  getSignUpLink = () => {
    const product = this.props.product;
    const backendConfig = this.props.backendConfig;
    return get(backendConfig, 'signUpLink.' + product);
  };
}

export const EnhancedComponent = props => {
  if (isProductPresentInProductList(props.product)) {
    return <LoginOrSignUp {...props} />;
  } else {
    return <Redirect to={ROUTES.PRODUCT} />;
  }
};

function isProductPresentInProductList(product) {
  return product && productList[product];
}

export default WithBackendConfig(EnhancedComponent);
