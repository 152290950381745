import React, { useState } from 'react';
import PropTypes from 'prop-types';
import resendVerificationEmail from '../../../../../../api/actions/resendVerificationEmail/resendVerificationEmail';
import { Message } from '@solarwinds-cloud/cloud-ui';
import { redirectTo } from '../../../../../../utils/utils';
import ROUTES from 'routes.json';
import styles from './ResendVerificationEmailError.module.scss';

const messageStates = {
  INITIAL: 'INITIAL',
  SENT: 'SENT',
  LIMIT: 'LIMIT',
};

export function ResendVerificationEmailError(props) {
  const { email, product, redirectTo, resendVerificationEmail } = props;

  const [messageState, setMessageState] = useState(messageStates.INITIAL);
  return render();

  function render() {
    if (messageState === messageStates.INITIAL) {
      return renderEmailIsNotVerified();
    } else if (messageState === messageStates.LIMIT) {
      return renderEmailVerificationLimit();
    } else {
      return renderEmailVerificationSent();
    }
  }

  async function handleResendVerificationEmail() {
    try {
      const { status } = await resendVerificationEmail(email, product);
      switch (status) {
        case 200:
          setMessageState(messageStates.SENT);
          break;
        case 429:
          setMessageState(messageStates.LIMIT);
          break;
        default:
          throw new Error(`Uknown status from resend email verification: ${status}`);
      }
    } catch (e) {
      redirectTo(ROUTES.ERROR);
    }
  }

  function renderEmailVerificationSent() {
    return (
      <Message positive>
        <div data-test-success-message="verificationEmailSent">Verification email has been sent.</div>
      </Message>
    );
  }

  function renderEmailVerificationLimit() {
    return (
      <Message error>
        <div data-test-error-message="verificationEmailLimit">Verification email was requested too many times.</div>
      </Message>
    );
  }

  function renderEmailIsNotVerified() {
    return (
      <Message error>
        <div data-test-error-message="resendVerificationEmail">
          Email is not verified.{' '}
          <span
            onClick={() => handleResendVerificationEmail()}
            data-test-link="resendVerificationEmail"
            className={styles.link}
          >
            Resend
          </span>{' '}
          verification email.
        </div>
      </Message>
    );
  }
}

ResendVerificationEmailError.propTypes = {
  redirectTo: PropTypes.func.isRequired,
  resendVerificationEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  product: PropTypes.string,
};

export default props => (
  <ResendVerificationEmailError
    email={props.email}
    resendVerificationEmail={resendVerificationEmail}
    redirectTo={redirectTo}
    product={props.product}
  />
);
