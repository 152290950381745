import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@solarwinds-cloud/cloud-ui';

export const SubmitButton = ({ disabled, loading }) => (
  <Button type="submit" fluid primary size="big" disabled={disabled} loading={loading}>
    Send me instructions
  </Button>
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

SubmitButton.displayName = 'SubmitButton';
