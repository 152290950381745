export const ENDPOINTS = {
  LOGIN: '/v1/login',
  REQUEST_RESET: '/v1/request-reset',
  CREATE_PROFILE: '/v1/create-profile',
  BACKEND_CONFIG: '/v1/backend-config',
  RESET: '/v1/reset',
  USER: '/v1/user',
  RESEND_VERIFICATION_EMAIL: '/v1/resend-verification-email',
  SAML_REDIRECT: '/v1/saml-redirect',
  MFA: '/v1/mfa',
  CREATE_AUTH_DEVICE: '/v1/auth-device',
};
