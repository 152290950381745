import React from 'react';
import { Grid, Image } from '@solarwinds-cloud/cloud-ui';
import styles from './RightSide.module.scss';
import cloudLogo from '../assets/product-group.png';
import { ProductContent } from './components/ProductContent';
import { productList } from '../../../config';

export const RightSide = ({ productList }) => {
  return (
    <Grid className={`${styles.gridWrapper} ${styles.noMargin}`} data-test-page-name="right-side">
      <Grid.Row className={styles.noPadding}>
        <h3>
          SolarWinds
          <span className={styles.registeredTrademark}>®</span> for DevOps
        </h3>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" className={styles.noPadding}>
        <div className={styles.description}>
          Quickly toggle between user experience, metrics, traces, and logs views to streamline troubleshooting.
          <a className={styles.link} href="https://www.solarwinds.com/devops" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </div>
      </Grid.Row>
      <Grid.Row centered>
        <Image className={styles.image} alt="SolarWinds Products" src={cloudLogo} />
      </Grid.Row>
      <Grid.Row centered>{renderProductsContent(productList)}</Grid.Row>
    </Grid>
  );
};

function renderProductsContent(productList) {
  return productList.map((product, index) => {
    return (
      <ProductContent
        key={index}
        description={product.description}
        productLink={product.productLink}
        name={product.marketingName}
        productKey={product.key}
      />
    );
  });
}

const enhanceRightSide = Component => props => {
  return (
    <Component
      productList={[productList['pingdom'], productList['appoptics'], productList['loggly'], productList['papertrail']]}
    />
  );
};

export default enhanceRightSide(RightSide);
