import React from 'react';
import _ from 'lodash';
import { productList } from '../../config';
import { Image } from '@solarwinds-cloud/cloud-ui';
import style from './LogosFooter.module.scss';

const logos = {
  appoptics: productList.appoptics.darkLogo,
  loggly: productList.loggly.darkLogo,
  papertrail: productList.papertrail.darkLogo,
  pingdom: productList.pingdom.darkLogo,
};

export default function LogosFooter(props) {
  const logosToRender = _.map(logos, (logo, key) => (
    <Image key={logo} src={logo} width="75px" alt={`Solarwinds ${key}`} />
  ));

  return <div className={style.logosHolder}>{logosToRender}</div>;
}
