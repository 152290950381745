import { ENDPOINTS } from '../../endpoints';
import { get } from '../../../utils/http/get';

export default async () => {
  const result = await get(ENDPOINTS.USER);
  assertResponse(result);

  return result.body;
};

function assertResponse(result) {
  if (result.status !== 200) {
    throw new Error(`Expected 200 but got ${result.status} while fetching a user. Body was ${result.body}`);
  }
}
