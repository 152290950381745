import React from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import ROUTES from 'routes.json';
import { supportLink } from '../../config';

export function VerificationTokenNotFound() {
  return (
    <div data-test-page-name="VerificationTokenNotFound">
      <CardLayout logoLink={ROUTES.PRODUCT}>
        <p className="lg">Your verification link is invalid.</p>
        <p>
          If you need help, <a href={supportLink}>contact our support team</a>.
        </p>
      </CardLayout>
    </div>
  );
}
