import React from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import ROUTES from 'routes.json';
import { supportLink } from '../../config';

export function MissingOrg() {
  return (
    <div data-test-page-name="MissingOrg">
      <CardLayout logoLink={ROUTES.PRODUCT}>
        <p className="lg">There is no organization associated with your user account.</p>
        <p>
          You can sign up with a different e-mail or <a href={supportLink}>contact our support team</a> to continue with
          the current e-mail address.
        </p>
      </CardLayout>
    </div>
  );
}
