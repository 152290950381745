import { generateRequestID } from '../utils';
import { getResponseBody } from './';

export async function get(endpointUrl) {
  const response = await fetch(endpointUrl, {
    headers: {
      'content-type': 'application/json',
      'x-request-id': generateRequestID(),
    },
    method: 'GET',
  });

  const responseBody = await getResponseBody(response);

  return {
    status: response.status,
    body: responseBody,
  };
}
