import React from 'react';

import styles from './UserInitials.module.scss';

export default function UserInitials({ name, surname, email }) {
  let initials = '';

  if (name && surname) {
    initials = name[0] + surname[0];
  } else if (email) {
    initials = email.slice(0, 2);
  }

  initials = initials.toUpperCase();

  return (
    <div data-test-text="initials" className={styles.initials}>
      {initials}
    </div>
  );
}
