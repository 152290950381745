import queryString from 'query-string';

export const getProductFromLocationSearch = search => {
  return queryString.parse(search)['client_id'];
};

export const getOrgIDFromLocationSearch = search => {
  return queryString.parse(search)['org_id'];
};

export const getEmailFromLocationSearch = search => {
  return queryString.parse(search)['email'];
};
