import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import styles from './SignUp.module.scss';
import { SuccessScreen } from './SuccessScreen';
import { SignUpForm } from './form/SignUpForm';
import ROUTES from 'routes.json';
import { DocumentTitle } from '../../components/DocumentTitle';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';

const FORM_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
};

export class SignUp extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    invitationToken: PropTypes.string.isRequired,
    productKey: PropTypes.string.isRequired,
    productLogo: PropTypes.string.isRequired,
    productDisplayName: PropTypes.string.isRequired,
    signUp: PropTypes.func.isRequired,
    redirectTo: PropTypes.func.isRequired,
    backendConfig: PropTypes.object.isRequired,
  };

  state = {
    firstName: '',
    lastName: '',
    formState: FORM_STATE.INITIAL,
    errors: {
      firstName: '',
      lastName: '',
      password: '',
      signUp: '',
    },
  };

  passwordInput = React.createRef();

  render = () => {
    if (!this.isEverythingLoaded()) {
      return <span data-test-loading>Loading...</span>;
    } else {
      return (
        <DocumentTitle title={`${this.props.productDisplayName} - Sign Up`}>
          <CardLayout logo={this.props.productLogo}>
            <p id="product-text">
              Create your SolarWinds profile to start using{' '}
              <span className={styles.productName}>{this.props.productDisplayName}</span>.
            </p>
            {this.renderForm()}
          </CardLayout>
        </DocumentTitle>
      );
    }
  };

  renderForm = () => {
    return (
      <SignUpForm
        {...this.state}
        productLinks={this.getLoginLinks()}
        email={this.props.email}
        password={this.getPassword()}
        passwordRef={this.passwordInput}
        loading={this.isFormLoading()}
        handleChangeName={this.handleChangeName}
        handleNameError={this.handleNameError}
        handlePasswordError={this.handlePasswordError}
        handleChangePassword={this.handleChangePassword}
        handleSubmit={this.handleSubmit}
      />
    );
  };

  renderSuccessScreen = () => {
    return <SuccessScreen />;
  };

  isEverythingLoaded = () => {
    const { loginLink } = this.props.backendConfig;
    return !!loginLink;
  };

  isUserSuccessfullySignedUp = () => {
    return this.state.formState === FORM_STATE.SUCCESS;
  };

  isFormLoading = () => {
    return this.state.formState === FORM_STATE.LOADING;
  };

  handleSubmit = async () => {
    try {
      this.startLoading();
      const redirectUrl = await this.signUp();
      this.props.redirectTo(redirectUrl);
    } catch (e) {
      this.stopLoading();
      this.setErrors({ signUp: 'An unexpected error occurred.' });
    }
  };

  startLoading = () => {
    this.setState({ formState: FORM_STATE.LOADING });
  };

  getLoginLinks = () => {
    const backendConfig = this.props.backendConfig;
    return get(backendConfig, 'loginLink');
  };

  stopLoading = () => {
    this.setState({ formState: FORM_STATE.INITIAL });
  };

  setErrors = errors => {
    this.setState(prevState => ({
      errors: { ...prevState.errors, ...errors },
    }));
  };

  signUp = async () => {
    const { firstName, lastName } = this.state;
    const data = {
      email: this.props.email,
      invitationToken: this.props.invitationToken,
      firstName,
      lastName,
      password: this.getPassword(),
      clientId: this.props.productKey,
    };
    const result = await this.props.signUp(data);
    return result.redirectUrl;
  };

  handleChangePassword = () => {
    this.setErrors({ password: '' });
  };

  handlePasswordError = errorMsg => {
    this.setErrors({ password: errorMsg });
  };

  handleChangeName = key => {
    return name => {
      this.setState({ [key]: name });
      this.setErrors({ [key]: '' });
    };
  };

  handleNameError = key => {
    return err => {
      this.setErrors({ [key]: err });
    };
  };

  getPassword() {
    return this.passwordInput.current?.inputRef?.current?.value || '';
  }
}

export const EnhancedComponent = ({
  logo,
  productKey,
  productDisplayName,
  signUp,
  email,
  invitationToken,
  backendConfig,
  redirectTo,
}) => {
  if (
    !isPopulatedString(email) ||
    !isPopulatedString(productKey) ||
    !isPopulatedString(invitationToken) ||
    logo === undefined
  ) {
    return <Redirect to={ROUTES.PRODUCT} />;
  }

  return (
    <SignUp
      email={email}
      productKey={productKey}
      invitationToken={invitationToken}
      productDisplayName={productDisplayName}
      productLogo={logo}
      redirectTo={redirectTo}
      signUp={signUp}
      backendConfig={backendConfig}
    />
  );
};

function isPopulatedString(value) {
  return typeof value === 'string' && value.length > 0;
}

export default WithBackendConfig(EnhancedComponent);
