import React from 'react';
import PropTypes from 'prop-types';

import styles from './HorizontalLinks.module.scss';

export default function HorizontalLinks({ fontWeight, fontSize, items }) {
  return (
    <div>
      {items.map(item => (
        <a
          style={{ fontSize, fontWeight }}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          data-test-link={item.title}
          className={styles.productLink}
          key={item.title}
        >
          {item.title}
        </a>
      ))}
    </div>
  );
}

HorizontalLinks.propTypes = {
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};
