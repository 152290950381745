import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';

export async function createProfile(post, data) {
  const result = await post(ENDPOINTS.CREATE_PROFILE, {
    email: data.email,
    invitationToken: data.invitationToken,
    firstName: data.firstName,
    lastName: data.lastName,
    password: data.password,
    clientId: data.clientId,
  });

  assertResponseSuccess(result);

  return result.body;
}

function assertResponseSuccess(res) {
  if (res.status >= 400) {
    const errorMessage = res.body && res.body.errorMessage;
    throw new Error(errorMessage || 'Server error, try again later.');
  }
}

export default data => createProfile(post, data);
