import XRegExp from 'xregexp';

export default function isValidName(name) {
  // Unsupported languages: Hindi, Bangla, Thai
  const regEx = XRegExp('^ *[\\p{L}\\p{M}\\p{N}\\p{P}\\p{S}][\\p{L}\\p{M}\\p{N}\\p{P}\\p{S} ]*$');

  if (typeof name !== 'string') return false;
  if (name.includes('@')) return false;
  if (name.length > 255) return false;
  return regEx.test(name);
}
