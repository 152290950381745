import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';

export default async function resendVerificationEmail(email, product) {
  const result = await post(ENDPOINTS.RESEND_VERIFICATION_EMAIL, {
    email: email,
    product: product,
  });

  if (result.status >= 500) {
    throw new Error(`Error: ${ENDPOINTS.RESEND_VERIFICATION_EMAIL} responded with ${result.status}`);
  }

  return result;
}
