import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';
import { isEmailValid } from '@solarwinds-cloud/swicus-validation';
import { ErrorLabel } from '../';

export class EmailInput extends Component {
  static propTypes = {
    onError: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    autoFocus: PropTypes.bool,
  };

  state = {
    showError: false,
  };

  render = () => {
    return (
      <Fragment>
        <Form.Field error={this.state.showError} required>
          <Input
            icon="email"
            iconPosition="left"
            name="email"
            aria-label="email"
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            placeholder="Email"
            required
            autoFocus={this.props.autoFocus}
            type="email"
            value={this.props.value}
            data-test-email-input
          />
        </Form.Field>
        {this.renderEmailErrorIfNecessary()}
      </Fragment>
    );
  };

  handleOnChange = (event, data) => {
    this.setState({ showError: false });
    this.props.onChange(data.value);

    if (!isEmailValid(data.value)) {
      this.props.onError('Enter a valid email address');
    }
  };

  handleOnBlur = () => {
    this.setState({ showError: !this.isEmailEmpty() && !isEmailValid(this.props.value) });
  };

  isEmailEmpty = () => this.props.value.length === 0;

  renderEmailErrorIfNecessary(errors) {
    return this.state.showError && <ErrorLabel data-test-error-label>Enter a valid email address</ErrorLabel>;
  }
}

const enhanceComponent = Component => ({ autoFocus, onError, onChange, value }) => {
  return <Component onError={onError} autoFocus={autoFocus} onChange={onChange} value={value} />;
};

const EnhancedComponent = enhanceComponent(EmailInput);
EnhancedComponent.displayName = 'EmailInput';

export default EnhancedComponent;
