import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import React, { Component } from 'react';
import { Form, Grid, Message } from '@solarwinds-cloud/cloud-ui';
import styles from './LoginForm.module.scss';
import { ForgotPassword, LoginButton, PasswordInput } from './components';
import { EmailInput } from '../../../../components';
import ROUTES from 'routes.json';
import { ErrorMessage } from '../../../../components/ErrorMessage';
import { getDemoUserPassword } from './demoUsers';
import { login } from '../../../../api/actions';
import { focus, redirectTo } from '../../../../utils/utils';
import ResendVerificationEmailError from './components/ResendVerificationEmailError/ResendVerificationEmailError';
import { translateErrorCodeToMessage } from '../../../../utils/translateErrorCode/translateErrorCode';
import ERROR_CODES from 'errorCodes.json';
import LocalAuthDisabledError from './components/LocalAuthDisabledError/LocalAuthDisabledError';
import queryString from 'query-string';
import { getEmailFromLocationSearch, getProductFromLocationSearch } from '../../../../utils/getFromLocationSearch';

const formStates = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
};

const EMPTY_ERROR_STATE = {
  email: '',
  loginError: '',
  ttl: '',
};

const NOTIFICATION = {
  VERIFICATION: 'verification',
  REQUIRE_LOCAL_LOGIN: 'require_local',
};

export class LoginForm extends Component {
  static propTypes = {
    redirectTo: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    focus: PropTypes.func.isRequired,
    locationSearch: PropTypes.string.isRequired,
    getDemoUserPassword: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const defaultEmail = getEmailFromLocationSearch(props.locationSearch) || '';
    this.defaultPassword = props.getDemoUserPassword(defaultEmail) || '';
    this.product = getProductFromLocationSearch(props.locationSearch) || '';
    this.notification = queryString.parse(props.locationSearch)['reason'];

    this.state = {
      email: defaultEmail,
      error: EMPTY_ERROR_STATE,
      formState: formStates.INITIAL,
    };
  }

  passwordInput = React.createRef();

  render = () => (
    <Form
      onSubmit={this.login}
      method="POST"
      error={!this.isFormValid() || !this.isLoginValid()}
      className={styles.loginForm}
      data-test-login-form
    >
      <Grid padded verticalAlign="middle">
        {this.renderInputsWithForgotPassword()}
        {this.renderLoginButton()}
      </Grid>
    </Form>
  );

  renderInputsWithForgotPassword = () => (
    <Grid.Column width={16}>
      {this.renderMessage()}
      {this.renderEmailInput()}
      {this.renderPasswordInput()}
      {this.renderForgotPassword()}
    </Grid.Column>
  );

  renderLoginButton = () => (
    <Grid.Column width={16} className={styles.loginButton}>
      <LoginButton disabled={this.isLoginDisabled()} loading={this.isLoading()}>
        Log in
      </LoginButton>
    </Grid.Column>
  );

  renderMessage = () => {
    if (this.state.error.loginError === ERROR_CODES.USER_EMAIL_NOT_VERIFIED) {
      return <ResendVerificationEmailError email={this.state.email} product={this.product} />;
    } else if (this.state.error.loginError === ERROR_CODES.LOCAL_LOGIN_NOT_PERMITTED) {
      return <LocalAuthDisabledError email={this.state.email} />;
    } else if (this.state.error.loginError) {
      return <ErrorMessage message={translateErrorCodeToMessage(this.state.error)} />;
    } else if (this.notification === NOTIFICATION.VERIFICATION) {
      return <Notification message="Confirm your email address to activate your account." />;
    } else if (this.notification === NOTIFICATION.REQUIRE_LOCAL_LOGIN) {
      return (
        <Notification message="You are a member of multiple organizations. Please log in with your SolarWinds credentials as well." />
      );
    }
  };

  renderEmailInput = () => (
    <EmailInput onChange={this.handleChangeEmail} onError={this.handleEmailError} value={this.state.email} autoFocus />
  );

  renderPasswordInput = () => (
    // make component uncontrolled to avoid exposing value by "inspect element"
    <PasswordInput
      input={this.passwordInput}
      onChange={this.handleChangePassword}
      defaultValue={this.defaultPassword}
    />
  );

  renderForgotPassword = () => (
    <ForgotPassword resetPasswordLink={ROUTES.RESET_PASSWORD} className={` ${styles.forgotPassword} forgotPassword`} />
  );

  isFormValid = () => {
    return this.state.error.email.length === 0 && this.state.email.length > 0 && this.getPassword().length > 0;
  };

  isLoginValid = () => !this.state.error.loginError;

  isLoginDisabled = () => {
    return this.isLoading() || !this.isFormValid();
  };

  isLoading = () => this.state.formState === formStates.LOADING;

  handleEmailError = err => {
    return this.setState({ error: { email: err } });
  };

  handleChangeEmail = email => {
    this.setState({
      email,
      error: EMPTY_ERROR_STATE,
    });
  };

  handleChangePassword = (event, data) => {
    this.forceUpdate();
  };

  login = async () => {
    try {
      this.startLoading();
      const result = await this.props.login(this.state.email, this.getPassword());
      this.props.redirectTo(result.redirectUrl);
    } catch (error) {
      if (error.message === ERROR_CODES.MISSING_ORG) {
        this.props.redirectTo(ROUTES.MISSING_ORG);
        return; // prevent displaying error
      }

      await this.setState({
        error: { ...this.state.error, loginError: error.message, ttl: error.ttl || '' },
        formState: formStates.INITIAL,
      });
      this.focusPasswordInput();
    }
  };

  startLoading = () => {
    this.setState({ error: EMPTY_ERROR_STATE, formState: formStates.LOADING });
  };

  focusPasswordInput = () => this.props.focus(this.passwordInput);

  getPassword = () => {
    return this.passwordInput.current?.inputRef?.current?.value || '';
  };
}

const EnhancedLoginForm = ({ location }) => {
  return (
    <LoginForm
      login={login}
      focus={focus}
      redirectTo={redirectTo}
      locationSearch={location.search}
      getDemoUserPassword={getDemoUserPassword}
    />
  );
};

function Notification(props) {
  return <Message color="yellow" content={props.message} data-test-notification-message />;
}

export default withRouter(EnhancedLoginForm);
