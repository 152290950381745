import styles from '../ProductSelector.module.scss';
import React from 'react';
import { Image } from '@solarwinds-cloud/cloud-ui';
import { sortObjectByKeysAlphabetically } from '../../../utils/utils';

export const ProductList = ({ productLinks, logos, orgId }) => {
  const sortedProductLinks = getSortedProductLinks();

  return Object.keys(sortedProductLinks).map((key, index) => (
    <a
      className={styles.link}
      href={getProductLink(key)}
      key={index}
      id={'product-' + (index + 1)}
      data-test-product-link
    >
      <Image src={logos[key]} height="47" alt={`Solarwinds ${key}`} />
    </a>
  ));

  function getProductLink(key) {
    if (orgId) {
      const url = new URL(productLinks[key]);
      url.searchParams.set('swicus_org_id', orgId);
      return url;
    }
    return productLinks[key];
  }

  function getSortedProductLinks() {
    return sortObjectByKeysAlphabetically(productLinks);
  }
};
