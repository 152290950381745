import React from 'react';
import { Grid } from '@solarwinds-cloud/cloud-ui';
import LoginForm from '../../../pages/Login/left-side/form/LoginForm';
import StartFreeTrial from './start-free-trial/StartFreeTrial';
import WithTheme from '../../../components/WithTheme';
import { excludedStartFreeTrialThemes } from '../../../config';
import { SamlLink } from './saml-link/SamlLink';
import ROUTES from 'routes.json';
import { WithBackendConfig } from '../../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';
import styles from './LoginLeftSide.module.scss';

export const LoginLeftSide = ({ themeProps, backendConfig }) => {
  const theme = themeProps.theme;

  return (
    <div className={styles.container}>
      <LoginForm />
      <Grid padded verticalAlign="middle">
        <Grid.Column width={16} textAlign="center" id={styles.ssoContainer}>
          {renderSamlLink()}
          {renderStartFreeTrialIfNeeded()}
        </Grid.Column>
      </Grid>
    </div>
  );

  function renderStartFreeTrialIfNeeded() {
    return !excludedStartFreeTrialThemes.includes(theme.key) && <StartFreeTrial theme={theme} />;
  }

  function renderSamlLink() {
    return shouldDisplaySSOLink() && <SamlLink samlLink={ROUTES.SAML_LOGIN} />;
  }

  function shouldDisplaySSOLink() {
    const samlFeatureFlag = get(backendConfig, 'featureFlags.saml');
    const logglySamlFeatureFlag = get(backendConfig, 'featureFlags.logglySaml');

    return samlFeatureFlag && (theme.key === 'loggly' ? Boolean(logglySamlFeatureFlag) : true);
  }
};

export default WithTheme(WithBackendConfig(LoginLeftSide));
