import React, { useState } from 'react';
import { withRouter } from 'react-router';
import ROUTES from 'routes.json';
import styles from './SamlLogin.module.scss';
import { LoginLayout } from '../../layouts';
import { EmailInput, RouterLink } from '../../components';
import { Form, Grid } from '@solarwinds-cloud/cloud-ui';
import { LoginButton } from '../Login/left-side/form/components';
import { samlRedirect } from '../../api/actions';
import { redirectTo } from '../../utils/utils';
import { ErrorMessage } from '../../components/ErrorMessage';
import { getEmailFromLocationSearch, getOrgIDFromLocationSearch } from '../../utils/getFromLocationSearch';

export const SamlLogin = () => {
  return (
    <div data-test-page-name="saml-login">
      <LoginLayout LeftSidePanel={withRouter(SamlLoginLeftSide)} />
    </div>
  );
};

export function SamlLoginLeftSide(props) {
  const defaultEmail = getEmailFromLocationSearch(props.location.search) || '';
  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const preferedOrgID = getOrgIDFromLocationSearch(props.location.search) || '';

  return (
    <>
      {renderForm()}
      <Grid padded verticalAlign="middle">
        <Grid.Column width={16} textAlign="center">
          {renderBackLink()}
        </Grid.Column>
      </Grid>
    </>
  );

  function renderForm() {
    return (
      <Form
        className={styles.samlLoginForm}
        onSubmit={loginWithSaml}
        method="POST"
        error={!isFormValid() || !isLoginValid()}
        data-test-saml-login-form
      >
        <Grid padded verticalAlign="middle">
          <Grid.Column width={16}>
            {!isLoginValid() && <ErrorMessage message={loginError} />}
            {renderEmailInput()}
            {renderLoginButton()}
          </Grid.Column>
        </Grid>
      </Form>
    );
  }

  function renderEmailInput() {
    return (
      <div className={styles.emailInput}>
        <EmailInput onChange={handleChangeEmail} onError={handleEmailError} value={email} autoFocus />
      </div>
    );
  }

  function renderLoginButton() {
    return (
      <LoginButton className={styles.loginButton} disabled={isLoginDisabled()} loading={loading}>
        Log in with Single Sign On
      </LoginButton>
    );
  }

  function renderBackLink() {
    return (
      <p data-test-block="backLink">
        <RouterLink data-test-link="backLink" to={ROUTES.LOGIN}>
          <b>Return to login screen</b>
        </RouterLink>
      </p>
    );
  }

  function handleChangeEmail(value) {
    setEmailError('');
    setEmail(value);
  }

  function handleEmailError(error) {
    setEmailError(error);
  }

  function isFormValid() {
    return emailError.length === 0 && email.length > 0;
  }

  function isLoginValid() {
    return !loginError;
  }

  function isLoginDisabled() {
    return !isFormValid();
  }

  async function loginWithSaml() {
    try {
      setLoading(true);

      const redirectUri = await samlRedirect(email, preferedOrgID);
      redirectTo(redirectUri);
    } catch (e) {
      setLoading(false);
      setLoginError(e.message);
    }
  }
}
