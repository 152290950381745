import { Grid } from '@solarwinds-cloud/cloud-ui';
import styles from '../RightSide.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

export const ProductContent = ({ description, productLink, name, productKey }) => {
  return (
    <Grid.Column width={4} data-type="productContent" className={styles.productContent}>
      <div className={styles.productContent_productName}>
        <h3 data-product-name>{name}</h3>
      </div>
      <div data-product-description className={styles.description}>
        {description}
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-test-product-link={productKey}
        className={styles.link}
        href={productLink}
      >
        Learn More
      </a>
    </Grid.Column>
  );
};

ProductContent.propTypes = {
  description: PropTypes.object.isRequired,
  productLink: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
