import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';

export async function resetPassword(post, email) {
  const result = await post(ENDPOINTS.REQUEST_RESET, {
    email: email,
  });

  assertResponseSuccess(result);
}

function assertResponseSuccess(res) {
  if (res.status > 404) {
    throw new Error(`Server response was ${res.status}, expected status code below 404.`);
  }
}

export default email => resetPassword(post, email);
