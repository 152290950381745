import React, { Component } from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import ROUTES from 'routes.json';
import { ProductLinks } from '../../components/ProductLinks/ProductLinks';

export default class NoMatch extends Component {
  render() {
    return (
      <div data-test-page-name="NoMatch">
        <CardLayout logoLink={ROUTES.PRODUCT}>
          <h1 className="warning xl">404</h1>
          <p className="lg">
            Sorry, we could not find the page <br />
            you were looking for.
          </p>
          <p>Continue to our products below:</p>
          <ProductLinks />
        </CardLayout>
      </div>
    );
  }
}
