import React from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import ROUTES from 'routes.json';
import { withRouter } from 'react-router-dom';
import { supportLink, samlDocLink } from '../../config';
import Cookies from 'js-cookie';

import COOKIE_NAMES from 'cookieNames.json';

const ErrorPageComponent = () => {
  const rawError = Cookies.get(COOKIE_NAMES.SWICUS_ERROR);
  Cookies.remove(COOKIE_NAMES.SWICUS_ERROR);
  let error = { message: 'Something went wrong.', type: 'general' };
  if (rawError) {
    try {
      error = JSON.parse(rawError);
    } catch (e) {
      error.message = rawError;
    }
  }

  return (
    <div data-test-page-name="Error">
      <CardLayout logoLink={ROUTES.PRODUCT}>
        <p className="lg">{error.message}</p>
        <p>{error?.message ? '' : 'Try to repeat the action.'}</p>
        {error?.type === 'saml' && (
          <p>
            <a href={samlDocLink}>View SAML documentation</a>
          </p>
        )}
        <p>
          If you need help, <a href={supportLink}>contact our support team</a>.
        </p>
      </CardLayout>
    </div>
  );
};

export const ErrorPage = withRouter(ErrorPageComponent);
