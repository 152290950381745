import React, { Fragment } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import {
  ResetPassword,
  ChangePassword,
  ProductSelector,
  NoMatch,
  SignUp,
  LoginOrSignUp,
  InvalidInvitation,
  Login,
  ExportCompliance,
} from './pages';
import { Main } from './layouts';
import './App.scss';
import { parseQueryString } from './utils/parseQueryString/parseQueryString';
import { productList } from './config';
import { ThemeProvider, ExternalScripts } from './components';
import { createProfile } from './api/actions';
import fetchUser from './api/actions/fetchUser/fetchUser';
import ROUTES from 'routes.json';
import { redirectTo } from './utils/utils';
import queryString from 'query-string';
import BackendConfigProvider from './providers/BackendConfigProvider/BackendConfigProvider';
import VerifiedEmail from './pages/VerifiedEmail/VerifiedEmail';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { VerificationTokenNotFound } from './pages/VerificationTokenNotFound/VerificationTokenNotFound';

import '@solarwinds-cloud/cloud-ui/dist/swicuswebapp.css';
import Analytics from './components/Analytics';
import { SamlLogin } from './pages/SamlLogin/SamlLogin';
import { Mfa } from './pages/Mfa/Mfa';
import { MissingOrg } from './pages/MissingOrg/MissingOrg';

const App = () => (
  <Router>
    <BackendConfigProvider>
      <Analytics>
        <ThemeProvider>
          <Fragment>
            <Main>
              <Switch>
                <Route exact path={ROUTES.LOGIN} render={props => renderLogin(props.location.search)} />
                <Route path={ROUTES.SET_PASSWORD + '/:token?'}>
                  <ChangePassword firstTime />
                </Route>
                <Route path={ROUTES.CHANGE_PASSWORD + '/:token?'}>
                  <ChangePassword />
                </Route>
                <Route exact path={ROUTES.RESET_PASSWORD}>
                  <ResetPassword />
                </Route>
                <Route path={ROUTES.MFA} render={props => renderMfa(props.location.search)} />
                <Route path={ROUTES.SAML_LOGIN} component={SamlLogin} />
                <Route path={ROUTES.VERIFIED_EMAIL} render={props => renderVerifiedEmail(props.location.search)} />
                <Route path={ROUTES.VERIFICATION_TOKEN_NOT_FOUND} component={VerificationTokenNotFound} />
                <Route path={ROUTES.MISSING_ORG} component={MissingOrg} />
                <Route path={ROUTES.ERROR} component={ErrorPage} />
                <Route path={ROUTES.SIGN_UP} render={props => renderSignUp(props.location.search)} />
                <Route
                  exact
                  path={ROUTES.LOGIN_OR_SIGN_UP}
                  render={props => renderLoginOrSignUp(props.location.search)}
                />
                <Route exact path={ROUTES.PRODUCT} component={ProductSelector} />
                <Route exact path={ROUTES.INVALID_INVITATION} component={InvalidInvitation} />
                <Route exact path={ROUTES.EXPORT_COMPLIANCE} component={ExportCompliance} />
                <Route component={NoMatch} />
              </Switch>
            </Main>
            <ExternalScripts />
          </Fragment>
        </ThemeProvider>
      </Analytics>
    </BackendConfigProvider>
  </Router>
);

function renderSignUp(search) {
  const product = parseQueryString('product', search);
  const email = parseQueryString('email', search);
  const invitationToken = parseQueryString('invitation_token', search);
  const logo = productList[product] && productList[product].darkLogo;
  const productKey = productList[product] && product;
  const productDisplayName = productList[product] && productList[product].name;

  return (
    <SignUp
      logo={logo}
      signUp={createProfile}
      productKey={productKey}
      productDisplayName={productDisplayName}
      email={email}
      redirectTo={redirectTo}
      invitationToken={invitationToken}
    />
  );
}

function renderMfa(search) {
  return hasParamInQueryString('otac', search) ? <Mfa /> : <Redirect to={ROUTES.LOGIN} />;
}

function renderLogin(search) {
  return hasParamInQueryString('client_id', search) ? <Login /> : <Redirect to={ROUTES.PRODUCT} />;
}

function renderLoginOrSignUp(search) {
  const product = parseQueryString('product', search);
  return <LoginOrSignUp product={product} redirectTo={redirectTo} fetchUser={fetchUser} />;
}

function renderVerifiedEmail(search) {
  const product = parseQueryString('product', search);
  return <VerifiedEmail product={product} />;
}

function hasParamInQueryString(param, search) {
  return queryString.parse(search)[param];
}

export default App;
