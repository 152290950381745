import React from 'react';
import { Grid, Image } from '@solarwinds-cloud/cloud-ui';
import mktImage from '../../assets/swo-marketing.svg';
import styles from '../../../shared/right-side/RightSide.module.scss';

const LoginRightSideComponent = () => {
  return (
    <Grid className={`${styles.gridWrapper} ${styles.noMargin}`} data-test-page-name="right-side">
      <Grid.Row className={styles.noPadding}>
        <h3>SolarWinds Observability SaaS</h3>
      </Grid.Row>
      <Grid.Row verticalAlign="middle" className={styles.noPadding}>
        <div className={styles.description}>
          SaaS-delivered Observability built to extend visibility across cloud-native, on-prem, and hybrid technology
          stacks.
          <a
            className={styles.link}
            href="https://www.solarwinds.com/solarwinds-observability"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </Grid.Row>
      <Grid.Row centered>
        <Image className={styles.image} alt="SolarWinds Observability" src={mktImage} />
      </Grid.Row>
    </Grid>
  );
};

export default LoginRightSideComponent;
