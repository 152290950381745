import React from 'react';
import { Image } from '@solarwinds-cloud/cloud-ui';
import styles from './CardLayout.module.scss';
import { CenterLayout } from '../index';
import { Link } from 'react-router-dom';
import solarwindsLogo from '../../themes/shared/assets/solarwinds-logo-white.svg';

const Logo = ({ src }) => <Image inline className={styles.logo} alt="Logo" src={src} data-test-product-logo />;

export const CardLayout = ({ children, logo = solarwindsLogo, logoLink, wrapperClass }) => (
  <CenterLayout className={wrapperClass}>
    <div className={styles.wrapper}>
      {logoLink ? (
        <Link to={logoLink} className={styles.link}>
          <Logo src={logo} />
        </Link>
      ) : (
        <Logo src={logo} />
      )}
      {children}
    </div>
  </CenterLayout>
);
CardLayout.displayName = 'CardLayout';
