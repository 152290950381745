import React from 'react';
import { ThemeConsumer } from '../providers/ThemeProvider/ThemeProvider';

const WithTheme = Component => {
  return class WithTheme extends React.Component {
    render() {
      return <ThemeConsumer>{themeProps => <Component {...this.props} themeProps={themeProps} />}</ThemeConsumer>;
    }
  };
};

export default WithTheme;
