import React from 'react';
import HorizontalLinks from '../Links/HorizontalLinks';

const products = [
  { title: 'AppOptics', url: 'https://www.appoptics.com/' },
  { title: 'Loggly', url: 'https://www.loggly.com/' },
  { title: 'Papertrail', url: 'https://papertrailapp.com/' },
  { title: 'Pingdom', url: 'https://www.pingdom.com/' },
];

export const ProductLinks = props => <HorizontalLinks {...props} items={products} />;
