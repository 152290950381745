import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';
import { parseQueryString } from '../../../utils/parseQueryString/parseQueryString';

export default async () => {
  const accessCode = parseQueryString('otac', window.location.search);
  const result = await post(ENDPOINTS.CREATE_AUTH_DEVICE, { access_code: accessCode });

  if (result.status !== 200) {
    throw new Error(`Server response was ${result.status}, expected status code was 200.`);
  }

  return result.body;
};
