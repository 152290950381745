import React, { Component } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { changePassword } from '../../api/actions';
import ChangePasswordForm from './ChangePasswordForm';

import ROUTES from 'routes.json';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import PropTypes from 'prop-types';
import { formStates } from './formStates';
import { SlingshotLayout } from '../../layouts/SlingshotLayout/SlingshotLayout';

export class ChangePassword extends Component {
  static propTypes = {
    resetToken: PropTypes.string,
    changePassword: PropTypes.func.isRequired,
    firstTime: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formState: formStates.INITIAL,
      errors: {
        changePassword: '',
      },
    };
  }

  render() {
    if (this.isFormInInvalidTokenState()) {
      return redirectToResetPasswordWithError(this.props.firstTime);
    } else if (this.isFormInPasswordUpdatedState()) {
      return redirectToProductPageWithSuccess();
    }

    if (this.props.firstTime) {
      return (
        <div data-test-page-name="ChangePasswordFirstTime">
          <SlingshotLayout>
            <h1>COMPLETE YOUR REGISTRATION</h1>
            {this.renderPasswordRequirements()}
            {this.renderErrorIfNecessary()}
            {this.renderForm()}
          </SlingshotLayout>
        </div>
      );
    } else {
      return (
        <div data-test-page-name="ChangePassword">
          <CardLayout>
            {this.renderPasswordRequirements()}
            {this.renderErrorIfNecessary()}
            {this.renderForm()}
          </CardLayout>
        </div>
      );
    }
  }

  renderPasswordRequirements() {
    return (
      <p>
        Enter your new password. Password must be between 8 and 72&nbsp;characters long and contain at least one
        uppercase character, lowercase character, number, and symbol.
      </p>
    );
  }

  renderForm() {
    return (
      <ChangePasswordForm
        resetToken={this.props.resetToken}
        isLoading={this.isLoading()}
        onChange={formState => this.changeFormState(formState)}
        onError={error => this.setPasswordError(error)}
        changePassword={this.props.changePassword}
      />
    );
  }

  renderErrorIfNecessary() {
    return (
      this.state.errors.changePassword && (
        <ErrorMessage data-test-error-message error message={this.state.errors.changePassword} />
      )
    );
  }

  isLoading() {
    return this.state.formState === formStates.LOADING;
  }

  changeFormState(formState) {
    this.setState({ formState });
  }

  setPasswordError(error) {
    this.setState({
      errors: {
        changePassword: error,
      },
    });
  }

  isFormInMissingTokenState() {
    return this.state.formState === formStates.MISSING_TOKEN;
  }

  isFormInInvalidTokenState() {
    return this.state.formState === formStates.INVALID_TOKEN;
  }

  isFormInPasswordUpdatedState() {
    return this.state.formState === formStates.PASSWORD_UPDATED;
  }
}

function redirectToProductPageWithSuccess() {
  return (
    <Redirect
      exact
      to={{
        pathname: ROUTES.PRODUCT,
        state: {
          passwordChanged: true,
        },
      }}
    />
  );
}

function redirectToResetPasswordWithError(firstTime) {
  return (
    <Redirect
      exact
      to={{
        pathname: ROUTES.RESET_PASSWORD,
        state: {
          invalidResetPasswordToken: true,
          firstTime: firstTime,
        },
      }}
    />
  );
}

const ChangePasswordWithResetToken = Component => props => {
  return GetComponent();

  function GetComponent() {
    const resetToken = useParams()?.token;
    const isFirstTime = props.firstTime ?? false;

    if (!resetToken) {
      return redirectToResetPasswordWithError(isFirstTime);
    }
    return <Component resetToken={resetToken} changePassword={changePassword} firstTime={isFirstTime} {...props} />;
  }
};

export default ChangePasswordWithResetToken(ChangePassword);
