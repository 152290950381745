import React, { Component } from 'react';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import PropTypes from 'prop-types';

class ExternalScripts extends Component {
  static propTypes = {
    externalScripts: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.scriptsElement = React.createRef();
  }

  componentDidMount() {
    const externalScripts = this.props.externalScripts;
    if (externalScripts && this.scriptsElement.current) {
      this.renderAllScripts(externalScripts);
    }
  }

  render() {
    return <div ref={this.scriptsElement} />;
  }

  renderAllScripts(externalScripts) {
    externalScripts.map(key => {
      return this.renderScript(key);
    });
  }

  // React (HTML5) does not allow script tags to be dynamically added. See more at https://bit.ly/2Hx03Pw
  renderScript(key) {
    const script = document.createElement('script');
    script.src = key;
    script.async = true;
    script.setAttribute('data-test-external-script', '');
    this.scriptsElement.current.appendChild(script);
  }
}

export const ExternalScriptsWithBackendConfig = props => {
  const externalScripts = props?.backendConfig?.externalScripts;
  return externalScripts ? <ExternalScripts externalScripts={externalScripts} /> : '';
};

export default WithBackendConfig(ExternalScriptsWithBackendConfig);
