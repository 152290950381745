import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import ROUTES from 'routes.json';
import styles from './Mfa.module.scss';
import { LoginLayout } from '../../layouts';
import { RouterLink } from '../../components';
import { Grid, Form, Header, Checkbox } from '@solarwinds-cloud/cloud-ui';
import { LoginButton } from '../Login/left-side/form/components';
import { convertSecondsToDays, redirectTo } from '../../utils/utils';
import { ErrorMessage } from '../../components/ErrorMessage';
import { loginWithMfa, createAuthDevice } from '../../api/actions';
import { translateErrorCodeToMessage } from '../../utils/translateErrorCode/translateErrorCode';
import { parseQueryString } from '../../utils/parseQueryString/parseQueryString';
import PropTypes from 'prop-types';
import { CreateAuthDeviceModal } from './CreateAuthDeviceModal';
import { CodeInput } from '../../components/CodeInput/CodeInput';

export const Mfa = () => {
  return (
    <div data-test-page-name="mfa-login">
      <LoginLayout LeftSidePanel={withRouter(MfaLeftSide)} />
    </div>
  );
};

export const TOTP_CODE_LENGTH = 6;
export const BACKUP_CODE_LENGTH = 8;

const TWO_FACTOR_METHODS = {
  VALIDATE_CODE: 'validate-code',
  CREATE_DEVICE: 'create-device',
};

const EMPTY_ERROR_STATE = {
  sameCodes: '',
  loginError: '',
  ttl: '',
};

export function MfaLeftSide(props) {
  return (
    <>
      <MFAForm locationSearch={props.location.search} />
      <Grid padded verticalAlign="middle">
        <Grid.Column width={16} textAlign="center">
          {renderBackLink()}
        </Grid.Column>
      </Grid>
    </>
  );

  function renderBackLink() {
    return (
      <>
        <div className={styles.twoFactorText}>
          <b>Have you lost your authentication device?</b>
          <br />
          <b>Use recovery code instead of authenticator code</b>
        </div>
        <p data-test-block="backLink">
          <RouterLink data-test-link="backLink" to={ROUTES.LOGIN}>
            <b>Return to login screen</b>
          </RouterLink>
        </p>
      </>
    );
  }
}

export function MFAForm({ locationSearch }) {
  const [code, setCode] = useState('');
  const [consecutiveCode, setConsecutiveCode] = useState('');
  const [markDeviceAsTrusted, setMarkDeviceAsTrusted] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [configurationKey, setConfigurationKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(EMPTY_ERROR_STATE);
  const isSetupMode = parseQueryString('method', locationSearch) === TWO_FACTOR_METHODS.CREATE_DEVICE;
  const codeRef = useRef(null);

  useEffect(() => {
    if (isSetupMode && !qrCode) {
      createAuthDevice()
        .then(result => {
          setQrCode(result['qr_code_base64']);
          setConfigurationKey(result['qr_code_secret']);
        })
        .catch(() => {
          setQrCode(null);
        });
    }
  }, [qrCode, isSetupMode]);

  const descriptionSetupText = 'Enter two consecutive authentication codes:';
  const descriptionText = 'Enter authentication code:';

  return (
    <>
      <div className={styles.twoFactorText}>
        <Header size="medium" color="grey" textAlign="center">
          Multi-Factor Authentication
        </Header>
        <Header size="tiny" color="grey" textAlign="center">
          {(isSetupMode && descriptionSetupText) || descriptionText}
        </Header>
      </div>
      <Form
        className={styles.mfaForm}
        onSubmit={mfaLogin}
        method="POST"
        error={!isLoginValid()}
        data-test-mfa-login-form
      >
        <Grid padded verticalAlign="middle">
          <Grid.Column width={16}>
            {loginError.loginError && <ErrorMessage message={translateErrorCodeToMessage(loginError, 'MFA')} />}
            {loginError.sameCodes && <ErrorMessage message={loginError.sameCodes} />}
            <CodeInput onChange={handleChangeCode} code={code} ref={codeRef} isSetupMode={isSetupMode} />
            {isSetupMode && (
              <CodeInput
                onChange={handleChangeConsecutiveCode}
                code={consecutiveCode}
                dataTestString={'data-test-consecutive-code-input'}
                isSetupMode
              />
            )}
            <span className={styles.checkboxContainer}>
              <Checkbox
                className={styles.checkboxText}
                checked={markDeviceAsTrusted}
                onChange={handleCheckboxChange}
                data-test-checkbox-input
                label={{ children: `Remember this device ${lifespanText()}`, className: styles.checkboxLabel }}
              />
            </span>
          </Grid.Column>
          <Grid.Column width={16} className={styles.loginButton}>
            <LoginButton disabled={!isCodeValid()} loading={loading}>
              Log in
            </LoginButton>
          </Grid.Column>
        </Grid>
      </Form>
      {isSetupMode && (
        <CreateAuthDeviceModal
          data-test-modal
          qrCode={qrCode}
          configurationKey={configurationKey}
          onCloseModal={focusCodeInput}
        />
      )}
    </>
  );

  function focusCodeInput() {
    codeRef.current.focus();
  }

  function handleChangeCode(value) {
    setCode(value);
    setErrorIfCodesAreEqual(value, consecutiveCode);
  }

  function handleChangeConsecutiveCode(value) {
    setConsecutiveCode(value);
    setErrorIfCodesAreEqual(code, value);
  }

  function setErrorIfCodesAreEqual(firstCode, secondCode) {
    if (firstCode === secondCode) {
      setLoginError({ ...loginError, sameCodes: 'The codes entered must be different' });
    } else {
      setLoginError(EMPTY_ERROR_STATE);
    }
  }

  function handleCheckboxChange(e, { checked }) {
    setMarkDeviceAsTrusted(checked);
  }

  function isCodeValid() {
    if (isSetupMode) {
      return (
        code.length === TOTP_CODE_LENGTH && consecutiveCode.length === TOTP_CODE_LENGTH && code !== consecutiveCode
      );
    }
    return code.length === TOTP_CODE_LENGTH || code.length === BACKUP_CODE_LENGTH;
  }

  function lifespanText() {
    const queryParamLifespan = parseQueryString('lifespan', locationSearch);
    const lifespan = !isNaN(queryParamLifespan) && convertSecondsToDays(queryParamLifespan);
    return (lifespan && `for ${lifespan} days`) || '';
  }

  function isLoginValid() {
    return isCodeValid() && !loginError.loginError;
  }

  async function mfaLogin() {
    try {
      setLoginError(EMPTY_ERROR_STATE);
      setLoading(true);
      const redirectUri = await loginWithMfa(code, consecutiveCode, markDeviceAsTrusted);
      redirectTo(redirectUri);
    } catch (e) {
      setCode('');
      setConsecutiveCode('');
      setLoading(false);
      setLoginError({ ...loginError, loginError: e.message, ttl: e.ttl || '' });
    }
  }
}

MFAForm.propTypes = {
  locationSearch: PropTypes.string.isRequired,
  markDeviceAsTrusted: PropTypes.bool,
};
