import React from 'react';

import styles from './ProductSelector.module.scss';
import { ProductList } from './ProductList/ProductList';
import { productList } from '../../config';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import { DocumentTitle } from '../../components/DocumentTitle';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const logos = {
  appoptics: productList.appoptics.simpleLogo,
  loggly: productList.loggly.simpleLogo,
  papertrail: productList.papertrail.simpleLogo,
  pingdom: productList.pingdom.simpleLogo,
};

export const ProductSelector = ({ backendConfig, location, logos }) => {
  let loginLinks = get(backendConfig, 'loginLink');
  let passwordChanged = get(location, 'state.passwordChanged');
  let orgId;
  if (location?.search) {
    const urlParams = new URLSearchParams(location.search);
    orgId = urlParams.get('swicus_org_id');
  }
  return (
    <DocumentTitle title="SolarWinds Cloud">
      <div data-test-page-name="ProductSelector">
        <CardLayout>
          <div>
            {renderPasswordChangedIfNecessary(passwordChanged)}
            <p>Continue by selecting your product below.</p>
          </div>
          <div className={styles.links}>{renderProductListIfPossible(loginLinks, logos, orgId)}</div>
        </CardLayout>
      </div>
    </DocumentTitle>
  );
};

ProductSelector.propTypes = {
  backendConfig: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logos: PropTypes.object.isRequired,
};

function renderProductListIfPossible(loginLinks, logos, orgId) {
  return loginLinks ? renderProductList(loginLinks, logos, orgId) : '';
}

function renderPasswordChangedIfNecessary(passwordChanged) {
  return passwordChanged ? renderPasswordChangedSuccessfully() : '';
}

function renderProductList(loginLinks, logos, orgId) {
  return <ProductList productLinks={loginLinks} logos={logos} orgId={orgId} />;
}

function renderPasswordChangedSuccessfully() {
  return <div id="password-reset-success">Success! Your new password has been saved.</div>;
}

const EnhancedProductSelector = props => <ProductSelector {...props} logos={logos} />;

export default WithBackendConfig(EnhancedProductSelector);
