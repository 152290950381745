import React from 'react';
import PropTypes from 'prop-types';
import { defaultSignUpLink } from '../../../../config';
import { WithBackendConfig } from '../../../../providers/BackendConfigProvider/BackendConfigProvider';

export const StartFreeTrial = props => {
  const productName = props.theme.name;

  return (
    <p data-test-block="startFreeTrial">
      Don't have a {productName} account? <br />
      <a href={getSignUpLink()} data-test-link="startFreeTrial">
        <b>Start free trial</b>
      </a>
    </p>
  );

  function getSignUpLink() {
    const { signUpLink } = props.backendConfig;
    const productKey = props.theme.key;

    if (!signUpLink) {
      return defaultSignUpLink;
    }

    if (doesSignUpLinksContainSelectedProduct(signUpLink, productKey)) {
      return signUpLink[productKey];
    } else {
      return defaultSignUpLink;
    }
  }

  function doesSignUpLinksContainSelectedProduct(signUpLink, productKey) {
    return !!signUpLink[productKey];
  }
};

StartFreeTrial.propTypes = {
  theme: PropTypes.object.isRequired,
  backendConfig: PropTypes.object.isRequired,
};

export default WithBackendConfig(StartFreeTrial);
