import React, { Component } from 'react';
import { LoginLayout } from '../../layouts';
import LoginLeftSide from '../../themes/shared/left-side/LoginLeftSide';

export class Login extends Component {
  render() {
    return (
      <div data-test-page-name="login">
        <LoginLayout LeftSidePanel={LoginLeftSide} />
      </div>
    );
  }
}

export default Login;
