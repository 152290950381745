import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoginLayout.module.scss';
import { Image } from '@solarwinds-cloud/cloud-ui';
import WithTheme from '../../components/WithTheme';
import { DocumentTitle } from '../../components/DocumentTitle';
import { ErrorPage } from '../../pages/ErrorPage/ErrorPage';
import RightSide from '../../pages/Login/right-side/RightSide';

const LoginLayout = ({ LeftSidePanel, themeProps }) => {
  const theme = themeProps.theme;
  const logoStyle = theme.key === 'swo' ? styles.logoWide : styles.logo;

  if (theme.name === 'nullTheme') {
    return <ErrorPage data-test-component-name="error" />;
  }

  return (
    <DocumentTitle title={getProductPageTitle(theme.name)}>
      <div className={`${styles.loginPage}`}>
        <div className={`${styles.leftSide} sidebar`}>
          <div className={styles.leftSideWrapper} data-test-page-name="left-side">
            <Image src={theme.lightLogo} className={logoStyle} alt={`${theme.name} logo`} />
            <div className={theme.styles.loginLeftSideBackground} />
            <LeftSidePanel />
          </div>
        </div>

        <div className={`${styles.rightSide}`}>
          <RightSide />
        </div>
      </div>
    </DocumentTitle>
  );

  function getProductPageTitle(productName) {
    return `${productName} - Log In`;
  }
};

LoginLayout.propTypes = {
  LeftSidePanel: PropTypes.func,
  themeProps: PropTypes.object,
};

export default WithTheme(LoginLayout);
