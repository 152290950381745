import React, { Fragment } from 'react';
import LoginRightSideComponent from './themes/shared/right-side/RightSide';
import SWOLoginRightSideComponent from './themes/swo/login/right-side-component/LoginRightSideComponent';
import pingdomLightLogo from './themes/pingdom/assets/pingdom-logo-white.svg';
import pingdomDarkLogo from './themes/pingdom/assets/pingdom-logo.svg';
import pingdomSimpleLogo from './themes/pingdom/assets/pingdom-logo-simple.svg';
import appopticsLightLogo from './themes/appoptics/assets/appoptics-logo-white.svg';
import appopticsDarkLogo from './themes/appoptics/assets/appoptics-logo.svg';
import appopticsSimpleLogo from './themes/appoptics/assets/appoptics-logo-simple.svg';
import logglyLightLogo from './themes/loggly/assets/loggly-logo-white.svg';
import logglyDarkLogo from './themes/loggly/assets/loggly-logo.svg';
import logglySimpleLogo from './themes/loggly/assets/loggly-logo-simple.svg';
import papertrailLightLogo from './themes/papertrail/assets/papertrail-logo-white.svg';
import papertrailDarkLogo from './themes/papertrail/assets/papertrail-logo.svg';
import papertrailSimpleLogo from './themes/papertrail/assets/papertrail-logo-simple.svg';
import adminpanelLightLogo from './themes/adminpanel/assets/adminpanel-logo-white.svg';
import solarwindsLightLogo from './themes/adminpanel/assets/solarwinds-logo-white.svg';
import swoLogo from './themes/swo/assets/swo-logo.svg';
import pingdomStyles from './themes/pingdom/styles.module.scss';
import appopticStyles from './themes/appoptics/styles.module.scss';
import logglyStyles from './themes/loggly/styles.module.scss';
import papertrailStyles from './themes/papertrail/styles.module.scss';
import adminpanelStyles from './themes/adminpanel/styles.module.scss';
import sharedStyles from './themes/shared/styles.module.scss';
import swoStyles from './themes/swo/styles.module.scss';
import styles from './themes/shared/right-side/RightSide.module.scss';

export const productList = withNewClientId({
  appoptics: {
    key: 'appoptics',
    name: 'AppOptics',
    marketingName: 'AppOptics™',
    description: (
      <span>
        APM, Metrics,
        <br /> Traces & Host Monitoring
      </span>
    ),
    darkLogo: appopticsDarkLogo,
    lightLogo: appopticsLightLogo,
    simpleLogo: appopticsSimpleLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: appopticStyles,
    productLink: 'https://www.appoptics.com/',
  },
  loggly: {
    key: 'loggly',
    name: 'Loggly',
    marketingName: (
      <Fragment>
        Loggly
        <span className={styles.registeredTrademark}>®</span>
      </Fragment>
    ),
    description: (
      <span>
        Log Monitoring <br />& Analytics
      </span>
    ),
    darkLogo: logglyDarkLogo,
    lightLogo: logglyLightLogo,
    simpleLogo: logglySimpleLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: logglyStyles,
    productLink: 'https://www.loggly.com/',
  },
  papertrail: {
    key: 'papertrail',
    name: 'Papertrail',
    marketingName: 'Papertrail™',
    description: <span>Log Management</span>,
    darkLogo: papertrailDarkLogo,
    lightLogo: papertrailLightLogo,
    simpleLogo: papertrailSimpleLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: papertrailStyles,
    productLink: 'https://papertrailapp.com/',
  },
  pingdom: {
    key: 'pingdom',
    name: 'Pingdom',
    marketingName: (
      <Fragment>
        Pingdom
        <span className={styles.registeredTrademark}>®</span>
      </Fragment>
    ),
    description: (
      <span>
        Web Performance <br />& User Experience Monitoring
      </span>
    ),
    darkLogo: pingdomDarkLogo,
    lightLogo: pingdomLightLogo,
    simpleLogo: pingdomSimpleLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: pingdomStyles,
    productLink: 'https://www.pingdom.com/',
  },
  adminpanel: {
    key: 'adminpanel',
    name: 'Administration',
    marketingName: 'administration',
    description: 'Administration Panel for products from AppMan family',
    darkLogo: solarwindsLightLogo,
    lightLogo: solarwindsLightLogo,
    simpleLogo: solarwindsLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: adminpanelStyles,
    productLink: 'https://admin.solarwinds.cloud',
  },
  ams: {
    key: 'ams',
    name: 'Agent Management Service',
    marketingName: 'ams',
    description: 'Agent Management Service for Snap',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  customer_portal: {
    key: 'customer_portal',
    name: 'Customer portal',
    marketingName: 'customer_portal',
    description: 'Customer portal',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  msp_dev: {
    key: 'msp_dev',
    name: 'MSP DEV',
    marketingName: 'msp_dev',
    description: 'msp dev',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  pss_dev: {
    key: 'pss_dev',
    name: 'PSS DEV',
    marketingName: 'pss_dev',
    description: 'pss dev',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  'slingshot.galactus': {
    key: 'slingshot.galactus',
    name: 'Infrastructure monitoring',
    marketingName: 'Infrastructure monitoring',
    description: 'slingshot.galactus',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  skeletor: {
    key: 'skeletor',
    name: 'Skeletor',
    marketingName: 'skeletor',
    description: 'skeletor',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  'msp.mailassure': {
    key: 'msp.mailassure',
    name: 'MailAssure',
    marketingName: 'MailAssure',
    description: 'MailAssure',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  'sentryone.document': {
    key: 'sentryone.document',
    name: 'SentryOne',
    marketingName: 'SentryOne',
    description: 'SentryOne',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  aiops: {
    key: 'aiops',
    name: 'AIOps',
    marketingName: 'AIOps',
    description: 'AIOps',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  webapp: {
    key: 'webapp',
    name: 'WebApp',
    marketingName: 'WebApp',
    description: 'WebApp',
    darkLogo: adminpanelLightLogo,
    lightLogo: adminpanelLightLogo,
    simpleLogo: adminpanelLightLogo,
    RightSideComponent: LoginRightSideComponent,
    styles: sharedStyles,
    productLink: 'https://solarwinds.com',
  },
  swo: {
    key: 'swo',
    name: 'Solarwinds Observability',
    marketingName: 'Solarwinds Observability SaaS',
    description:
      'SaaS-delivered Observability built to extend visibility across cloud-native, on-prem, and hybrid technology stacks.',
    darkLogo: swoLogo,
    lightLogo: swoLogo,
    simpleLogo: swoLogo,
    RightSideComponent: SWOLoginRightSideComponent,
    styles: swoStyles,
    productLink: 'https://solarwinds.com',
  },
});

function withNewClientId(config) {
  const newConfig = {};

  Object.keys(config).forEach(productKey => {
    newConfig[productKey + '_new'] = config[productKey];
  });

  return { ...config, ...newConfig };
}

export const demoUsersLogins = {
  'sam-ao+demo@solarwinds.cloud': 'solarwindsabc123',
};

export const defaultSignUpLink = 'https://www.solarwinds.com/devops';

export const excludedStartFreeTrialThemes = ['adminpanel'];

export const supportLink = 'https://customerportal.solarwinds.com/support/submit-a-ticket?apm-converged-support';

export const samlDocLink = 'https://documentation.solarwinds.com/en/success_center/apm/content/shared/saml.htm';
