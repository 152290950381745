import React from 'react';
import PropTypes from 'prop-types';
import { WithBackendConfig } from '../providers/BackendConfigProvider/BackendConfigProvider';
import ReactGA from 'react-ga';

const Analytics = ({ children, backendConfig }) => {
  if (backendConfig && backendConfig.gaTrackingCode) {
    ReactGA.initialize(backendConfig.gaTrackingCode);
    ReactGA.ga('send', {
      hitType: 'pageview',
      page: window.location.pathname,
      location: redactParams(window.location, ['otac', 'nonce', 'state']),
    });
  }

  return <>{children}</>;
};

Analytics.propTypes = {
  backendConfig: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

Analytics.displayName = 'Analytics';

export default WithBackendConfig(Analytics);

export function redactParams(location, params) {
  let search = location.search;
  params.forEach(param => {
    // do string replacement instead of parsing to keep param order
    const re = new RegExp('(\\?|&)(' + param + ')=[^&]*');
    search = search.replace(re, '$1$2=...');
  });

  return location.protocol + '//' + location.host + location.pathname + search;
}
