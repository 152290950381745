import React from 'react';
import { CardLayout } from '../../layouts/CardLayout/CardLayout';
import solarwindsLogoDark from '../../themes/shared/assets/solarwinds-logo.svg';

const ExportCompliance = () => (
  <div data-test-page-name="ExportCompliance">
    <CardLayout logo={solarwindsLogoDark}>
      <h1 className="warning">Access Denied</h1>
      <p className="lg">
        This site is not available in your region. <br />
        We apologize for the inconvenience, and thank you for your interest in SolarWinds<sup>&reg;</sup>.
      </p>
    </CardLayout>
  </div>
);
export default ExportCompliance;
