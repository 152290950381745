import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';
import { ErrorLabel } from '../';
import { BACKUP_CODE_LENGTH, TOTP_CODE_LENGTH } from '../../pages/Mfa/Mfa';

export const CodeInput = React.forwardRef(({ onChange, code, dataTestString, isSetupMode }, ref) => {
  const [showError, setShowError] = useState(false);
  const inputType = isSetupMode ? 'number' : 'text';
  return (
    <>
      <Form.Field error={showError} required>
        <Input
          ref={ref}
          icon="locked"
          iconPosition="left"
          aria-label="code"
          onChange={handleChangeCode}
          onBlur={handleOnBlur}
          placeholder="6-digit-code"
          required
          type={inputType}
          data-test-id={dataTestString}
          value={code}
          autoFocus={!isSetupMode}
        />
      </Form.Field>
      {showError && <ErrorLabel data-test-error-label>Enter a valid code</ErrorLabel>}
    </>
  );

  function handleChangeCode(event, data) {
    setShowError(false);
    onChange(data.value);
  }

  function handleOnBlur() {
    if (isSetupMode) {
      setShowError(code.length !== TOTP_CODE_LENGTH);
    } else {
      setShowError(code.length !== TOTP_CODE_LENGTH && code.length !== BACKUP_CODE_LENGTH);
    }
  }
});

CodeInput.defaultProps = {
  dataTestString: 'data-test-code-input',
};

CodeInput.propTypes = {
  onChange: PropTypes.func,
  code: PropTypes.string,
  dataTestString: PropTypes.string,
  isSetupMode: PropTypes.bool,
};
