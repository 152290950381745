import { Button, Icon, Image, Modal, Header, Accordion, Message } from '@solarwinds-cloud/cloud-ui';
import styles from './Mfa.module.scss';
import React, { useState } from 'react';
import { redirectTo } from '../../utils/utils';
import ROUTES from 'routes.json';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { supportLink } from '../../config';

export function CreateAuthDeviceModal({ qrCode, configurationKey, onCloseModal }) {
  const [open, setOpen] = useState(true);
  const [showOrHideText, setShowOrHideText] = useState('Show');
  const [copySuccess, setCopySuccess] = useState(false);

  const authenticationApps = {
    Google_Authenticator: {
      android: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
      ios: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
    },
    Microsoft_Authenticator: {
      android: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
      ios: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
    },
    Authy: {
      android: 'https://play.google.com/store/apps/details?id=com.authy.authy',
      ios: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
    },
    LastPass: {
      android: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator',
      ios: 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004',
    },
  };

  const panels = [
    {
      key: 'configuration-key',
      title: {
        className: styles.accordionTitle,
        content: (
          <span className={styles.linkColor}>{`${showOrHideText} the secret key for manual configuration`}</span>
        ),
        icon: <Icon className={styles.linkColor} name="dropdown" />,
      },
      content: {
        content: (
          <div className={styles.accordionContent}>
            <p>Type the secret configuration key below when prompted by your MFA application.</p>
            <div className={styles.secretKey}>
              <Header className={styles.qrSecret} size="small" textAlign="center">
                {configurationKey}
              </Header>
              <span className={classNames(styles.spanLink, { [styles.copied]: copySuccess })} onClick={clickCopy}>
                {copySuccess ? 'Copied' : 'Copy'}
              </span>
            </div>
          </div>
        ),
      },
    },
  ];

  function clickCopy() {
    copy(configurationKey);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  }

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
    if (onCloseModal) {
      onCloseModal();
    }
  }

  function cancel() {
    redirectTo(ROUTES.LOGIN);
  }

  function onTitleClick(e, data) {
    if (data.active) {
      setShowOrHideText('Show');
    } else {
      setShowOrHideText('Hide');
    }
  }

  return (
    <Modal
      open={open}
      onOpen={openModal}
      onClose={closeModal}
      size="small"
      centered={false}
      style={{ marginTop: '5%' }}
    >
      <Modal.Header>Setup Multi-Factor Authentication</Modal.Header>
      <Modal.Content className={styles.modalContent}>
        <div className={styles.modalInfo}>
          <Icon name="info-outline" className={styles.modalIcon} />
          Your organization owner enabled multi-factor authentication (MFA) for your organization. Follow the next steps
          to configure MFA for your account.
        </div>
        <Header sub size="huge">
          Complete the following steps to configure your mobile app.
        </Header>
        <div className={styles.instructions}>
          <p>
            1. Install an authenticator app on your mobile device. You can use any authentication app, but we recommend
            the following:
          </p>
          <div className={styles.downloadLinksContainer}>
            <span className={styles.osTitle}>Android:</span>
            {renderDownloadApps('android')}
          </div>
          <div className={styles.downloadLinksContainer}>
            <span className={styles.osTitle}>iOS:</span>
            {renderDownloadApps('ios')}
          </div>
          <p>2. In the authenticator app, choose to add an account and scan the following QR code when prompted.</p>
          {renderQrIfNecessary(qrCode)}
          {configurationKey && <Accordion panels={panels} onTitleClick={onTitleClick} />}
          <p>3. Once the account is added and shows a six-digit code, choose “Next”.</p>
          <div className={styles.modalInfo}>
            <Icon name="info-outline" className={styles.modalIcon} />
            Keep a copy of your recovery codes in a safe place. Use these codes to log in if you lose access to your
            device. After completing the setup, download or copy the recovery codes from your Profile page.
          </div>
          <div className={styles.modalInfo}>
            <Icon name="help-outline" className={styles.modalIcon} />
            <a
              className={styles.linkColor}
              target="_blank"
              rel="noopener noreferrer"
              href="https://documentation.solarwinds.com/en/success_center/apm/default.htm#cshid=apm_mfa-user"
            >
              MFA documentation
            </a>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={closeModal} primary>
          <span>Next</span>
        </Button>
      </Modal.Actions>
    </Modal>
  );

  function renderQrIfNecessary(qrCode) {
    if (qrCode === null) {
      const errorText = 'Please cancel and try again, ';
      const content = (
        <p>
          {errorText}
          <a className={styles.linkColor} href={{ supportLink }}>
            or contact support.
          </a>
        </p>
      );
      return (
        <Message
          error
          header="QR code did not generated properly"
          content={content}
          data-test-error-message
          floated="left"
        />
      );
    }
    return (
      <div className={styles.imageContent}>
        <Image src={`data:image/png;base64,${qrCode}`} size="small" alt="qrCode" />
      </div>
    );
  }

  function renderDownloadApps(osType) {
    return Object.keys(authenticationApps).map(app => {
      return (
        <a
          className={styles.link}
          href={authenticationApps[app][osType]}
          key={`${osType}_${app}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {app}
        </a>
      );
    });
  }
}
