import React from 'react';

import { Label } from '@solarwinds-cloud/cloud-ui';
import styles from './ErrorLabel.module.scss';

const ErrorLabel = ({ children }) => {
  return (
    <Label basic color="red" pointing="above" className={styles.errorLabel}>
      {children}
    </Label>
  );
};

ErrorLabel.displayName = 'ErrorLabel';

export default ErrorLabel;
