import getFormQuery from '../../../utils/getFormQuery';
import { ENDPOINTS } from '../../endpoints';
import { post } from '../../../utils/http/post';
import { parseQueryString } from '../../../utils/parseQueryString/parseQueryString';
import { assertResponseSuccess } from '../../../utils/loginError/loginError';

export async function loginWithMfa(post, code, consecutiveCode, markDeviceAsTrusted, loginQueryParams) {
  const otac = parseQueryString('otac', window.location.search);
  const result = await post(ENDPOINTS.MFA, {
    code: code,
    consecutive_code: consecutiveCode,
    mark_as_trusted_device: markDeviceAsTrusted,
    otac,
    loginQueryParams,
  });
  assertResponseSuccess(result);

  return result.body.redirectUrl;
}

export default (code, consecutiveCode, markDeviceAsTrusted) =>
  loginWithMfa(post, code, consecutiveCode, markDeviceAsTrusted, getFormQuery(window.location.search));
