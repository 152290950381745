import React from 'react';
import PropTypes from 'prop-types';

import styles from '../SignUp.module.scss';
import { Form, Input } from '@solarwinds-cloud/cloud-ui';

export const EmailInput = ({ email }) => (
  <Form.Field className={styles.field}>
    <Input name="email" className={styles.input} icon="email" iconPosition="left" disabled value={email} />
  </Form.Field>
);

EmailInput.displayName = 'EmailInput';
EmailInput.propTypes = {
  email: PropTypes.string.isRequired,
};
