import React from 'react';
import HorizontalLinks from '../Links/HorizontalLinks';
import { WithBackendConfig } from '../../providers/BackendConfigProvider/BackendConfigProvider';
import { get } from 'lodash';

export const ProductLoginLinks = props => (
  <HorizontalLinks {...props} items={createItems(get(props, 'backendConfig.loginLink'))} />
);

function createItems(backendLoginLinks) {
  if (!backendLoginLinks) {
    return [];
  }
  return [
    { title: 'AppOptics', url: backendLoginLinks.appoptics },
    { title: 'Loggly', url: backendLoginLinks.loggly },
    { title: 'Papertrail', url: backendLoginLinks.papertrail },
    { title: 'Pingdom', url: backendLoginLinks.pingdom },
  ];
}

export default WithBackendConfig(ProductLoginLinks);
