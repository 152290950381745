import PropTypes from 'prop-types';
import React from 'react';
import styles from './LoginButton.module.scss';
import { Button } from '@solarwinds-cloud/cloud-ui';

export const LoginButton = props => {
  return (
    <div className={styles.background}>
      <Button
        type="submit"
        size="big"
        fluid
        disabled={props.disabled}
        loading={props.loading}
        primary
        data-test-login-button
      >
        {props.children}
      </Button>
    </div>
  );
};

LoginButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
LoginButton.displayName = 'LoginButton';
